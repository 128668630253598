import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import axiosInstance from 'src/utils/axios';
import ItemUnitConverter from 'src/utils/units';

import { getPaginatedUnits } from 'src/redux/slices/units';
import { KITCHEN_CATEGORY_ID, UNIT_ID } from 'src/config-global';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  TCreateKitchenDispatchOrder,
  TCreateKitchenDispatchOrderItem,
} from 'src/redux/slices/kitchen-dispatch-order';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';

import { IUnitListItem } from 'src/types/unit';
import { IKitchenListItem } from 'src/types/kitchen';
import { IKitchenDispatchOrderDetailedItem } from 'src/types/kitchen-dispatches';

interface IKitchenDispatchNewItemRow {
  index: number;
  remove: (index: number) => void;
  isLast: boolean;
  isEdit: boolean;
  isCompleted: boolean;
  isReturnOrder: boolean;
  dispatchOrder: IKitchenDispatchOrderDetailedItem | undefined;
}

export default function KitchenDispatchNewItemRow({
  index,
  remove,
  isLast,
  isEdit,
  isCompleted,
  dispatchOrder,
  isReturnOrder,
}: IKitchenDispatchNewItemRow) {
  const dispatch = useAppDispatcher();

  const { units } = useAppSelector((state) => state.units);

  const [inventorySearchQuery, setInventorySearchQuery] = useState('');

  const [unitFamily, setUnitFamily] = useState<string | null>(null);

  const [localInventory, setLocalInventory] = useState<IKitchenListItem[]>([]);

  const [selectedItem, setSelectedItem] = useState<IKitchenListItem | null>(null);

  const {
    control,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<TCreateKitchenDispatchOrder>();

  const defaultHelperText: string = errors.items?.[index] ? ' ' : '';

  const adjustingQuantity = dispatchOrder && isCompleted ? dispatchOrder.items[index].quantity : 0;

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPaginatedKitchenItems = useCallback(
    debounce(async (query) => {
      try {
        const response = await axiosInstance.get(
          `inventory/kitchen/item?page=&limit=&generalSearch=${query}&itemName=&itemCode=&minQuantity=&maxQuantity=&minPrice=&maxPrice=&suppliers=&categories=${KITCHEN_CATEGORY_ID}&units=&alertEnabled=&alertSent=&availability=&sortBy=itemName&sort=asc&group&status=${
            isEdit ? '' : 'active'
          }&createdBy=`
        );

        setLocalInventory(response.data.data.items);

        const foundItem = response.data.data.items.find(
          (item: IKitchenListItem) => item._id === watchItems[index].inventoryItem
        );

        if (foundItem) {
          setSelectedItem(foundItem);
        }
      } catch (err) {
        console.warn(err);
      }
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    fetchPaginatedKitchenItems(inventorySearchQuery);

    return fetchPaginatedKitchenItems.cancel;
  }, [inventorySearchQuery, fetchPaginatedKitchenItems]);

  useEffect(() => {
    dispatch(getPaginatedUnits({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnitChange = useCallback(
    (unitID: string) => {
      try {
        if (unitID !== UNIT_ID) {
          const unit: IUnitListItem | undefined = units.units.find(
            (item: IUnitListItem) => item._id === unitID
          );

          if (unit && selectedItem) {
            const newQuantity = ItemUnitConverter.makeConvert(
              selectedItem.quantity,
              ItemUnitConverter.valueToUnit(selectedItem.unit.abbreviation),
              ItemUnitConverter.valueToUnit(unit.abbreviation)
            );

            setSelectedItem({
              ...selectedItem,
              quantity: newQuantity,
              unit,
            });

            handleQuantityChange(0);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [units, selectedItem]
  );

  const handleQuantityChange = (newQuantity: number) => {
    if (selectedItem && newQuantity > selectedItem.quantity + adjustingQuantity) {
      setError(`items.${index}.quantity`, {
        message:
          selectedItem.quantity > 0
            ? `Only ${selectedItem.quantity + adjustingQuantity} ${
                selectedItem.unit.abbreviation
              } left`
            : 'Out of stock',
        type: 'max',
      });
      setValue(`items.${index}.quantity`, newQuantity);
    } else {
      setValue(`items.${index}.quantity`, newQuantity);
      clearErrors(`items.${index}.quantity`);
    }
  };

  return (
    <TableRow>
      <TableCell>
        {index + 1 < 10 ? `0${index + 1}` : index + 1}

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>

      <TableCell>
        <Controller
          name={`items.${index}.inventoryItem`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              fullWidth
              size="small"
              multiple={false}
              disabled={isCompleted}
              options={localInventory.filter(
                (kitchenItem) =>
                  !watchItems
                    .map((watchItem: TCreateKitchenDispatchOrderItem) => watchItem.inventoryItem)
                    .includes(kitchenItem._id)
              )}
              isOptionEqualToValue={(option, value) => {
                if (typeof option === 'string') {
                  return option === value;
                }

                if (option && '_id' in option) {
                  return option._id === value;
                }

                return false;
              }}
              getOptionLabel={(option: IKitchenListItem | string) => {
                if (!option) {
                  return '';
                }

                if (typeof option === 'string') {
                  if (option.length === 0) {
                    return option;
                  }

                  const value = localInventory.find(
                    (kitchenItem: IKitchenListItem) => kitchenItem._id === option
                  );
                  return value ? value.itemName : '';
                }

                if (option && 'itemName' in option) {
                  return option.itemName;
                }

                return '';
              }}
              renderOption={(props, option) => {
                if (typeof option === 'string') {
                  return (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  );
                }

                return (
                  <li {...props} key={option._id}>
                    {option.itemName}
                  </li>
                );
              }}
              onInputChange={(_, value) => {
                setInventorySearchQuery(value);
              }}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  required
                  helperText={error ? error.message : defaultHelperText}
                  {...params}
                />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  field.onChange(newValue);
                  setSelectedItem(null);
                } else {
                  setSelectedItem(newValue);

                  setValue(`items.${index}.unit`, newValue?.unit._id ?? '');

                  setValue(`items.${index}.quantity`, 0);
                  clearErrors(`items.${index}.quantity`);

                  setUnitFamily(newValue?.unit.category ?? null);
                  field.onChange(newValue?._id);
                }
              }}
            />
          )}
        />
      </TableCell>

      <TableCell align="left" sx={{ maxWidth: 150 }}>
        <RHFTextField
          required
          type="number"
          size="small"
          disabled={!selectedItem}
          name={`items.${index}.quantity`}
          onChange={(e) => handleQuantityChange(Number(e.target.value))}
          InputProps={{
            endAdornment: errors?.items?.[index]?.quantity ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.quantity?.message || 'Invalid quantity'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : (
              <InputAdornment position="end">
                {selectedItem && (
                  <Box
                    sx={{
                      typography: 'subtitle2',
                      color: 'text.disabled',
                    }}
                  >
                    {`/ ${selectedItem.quantity + adjustingQuantity} ${
                      selectedItem.unit.abbreviation
                    }`}
                  </Box>
                )}
              </InputAdornment>
            ),
          }}
          helperText={defaultHelperText}
          showError={false}
          sx={{ maxWidth: 200 }}
        />
      </TableCell>

      <TableCell align="left">
        <Controller
          name={`items.${index}.unit`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              select
              fullWidth
              SelectProps={{
                sx: { textTransform: 'capitalize' },
              }}
              error={!!error}
              helperText={defaultHelperText}
              onChange={(e) => {
                field.onChange(e.target.value);

                onUnitChange(e.target.value);
              }}
              size="small"
              inputProps={{
                autoComplete: 'off',
              }}
            >
              {units.units
                .filter((unit: IUnitListItem) => (unitFamily ? unit.category === unitFamily : true))
                .map((unit: IUnitListItem) => (
                  <MenuItem key={unit._id} value={unit._id} sx={{ textTransform: 'unset' }}>
                    {unit.abbreviation}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      </TableCell>

      {isReturnOrder && (
        <>
          <TableCell align="right">
            <RHFTextField
              fullWidth
              size="small"
              required
              type="number"
              name={`items.${index}.unitPrice`}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        typography: 'subtitle2',
                        color: 'text.disabled',
                      }}
                    >
                      Rs.
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: errors?.items?.[index]?.unitPrice ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={errors?.items?.[index]?.unitPrice?.message || 'Invalid Unit Price'}
                    >
                      <Iconify icon="eva:info-outline" color="error.main" />
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
              helperText={defaultHelperText}
              showError={false}
            />
          </TableCell>

          <TableCell align="right">
            <RHFTextField
              fullWidth
              size="small"
              type="number"
              name={`items.${index}.discount`}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        typography: 'subtitle2',
                        color: 'text.disabled',
                      }}
                    >
                      Rs.
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: errors?.items?.[index]?.discount ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={errors?.items?.[index]?.discount?.message || 'Invalid Discount'}
                    >
                      <Iconify icon="eva:info-outline" color="error.main" />
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
              helperText={defaultHelperText}
              showError={false}
            />
          </TableCell>

          <TableCell align="right">
            <RHFTextField
              fullWidth
              size="small"
              type="number"
              name={`items.${index}.totalAfterDiscount`}
              placeholder="0.00"
              onChange={(event) => {
                setValue(
                  `items.${index}.unitPrice`,
                  Number(event.target.value) / watchItems[index].quantity
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        typography: 'subtitle2',
                        color: 'text.disabled',
                      }}
                    >
                      Rs.
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: errors?.items?.[index]?.totalAfterDiscount ? (
                  <InputAdornment position="end">
                    <Tooltip title={errors?.items?.[index]?.totalAfterDiscount?.message || ''}>
                      <Iconify icon="eva:info-outline" color="error.main" />
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
              helperText={defaultHelperText}
              showError={false}
            />
          </TableCell>
        </>
      )}

      <TableCell align="right">
        <Tooltip title={isLast ? 'Last item cannot be deleted' : 'Delete'}>
          <span>
            <IconButton disabled={isLast} color="error" onClick={() => remove(index)}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </span>
        </Tooltip>

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>
    </TableRow>
  );
}
