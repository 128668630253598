import { m } from 'framer-motion';
import { useCallback, useState } from 'react';

import { alpha, IconButton, Tooltip } from '@mui/material';

import logToucodeEvent, { APP_EVENTS } from 'src/utils/log-google-events';

import SvgColor from 'src/components/svg-color';
import { varHover } from 'src/components/animate';

export default function FullScreenOption() {
  const [fullscreen, setFullscreen] = useState(false);

  const onToggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }

    logToucodeEvent(APP_EVENTS.FULLSCREEN_MODE_TOGGLE, {
      fullscreen,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip title="Full Screen">
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={onToggleFullScreen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
        }}
        data-test-id="test_avatar_icon_button"
      >
        <SvgColor
          src={`/assets/icons/setting/${fullscreen ? 'ic_exit_full_screen' : 'ic_full_screen'}.svg`}
          sx={{ width: 16, height: 16 }}
        />
      </IconButton>
    </Tooltip>
  );
}
