import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Theme, SxProps } from '@mui/material/styles';

import ErrorContent from '../error-content';

type Props = {
  notFound: boolean;
  error: string;
  sx?: SxProps<Theme>;
};

export default function TableErrorData({ notFound, error, sx }: Props) {
  return (
    <TableRow>
      {notFound ? (
        <TableCell colSpan={12}>
          <ErrorContent
            filled
            title="Error loading data"
            description={error}
            sx={{
              py: 10,
              ...sx,
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
