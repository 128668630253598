import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICategoryListItem } from 'src/types/category';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  categories: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    categories: ICategoryListItem[];
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  categories: {
    count: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    categories: [],
  },
};

const categoriesSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaginatedCategories.fulfilled, (state, action) => {
        state.status = 'success';
        state.categories = action.payload;
      })
      .addCase(getPaginatedCategories.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default categoriesSlice.reducer;

export const getPaginatedCategories = createAsyncThunk(
  'categories/getPaginated',
  async (
    params: {
      page?: number | null;
      limit?: number | null;
      generalSearch?: string | null;
      name?: string | null;
      description?: string | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: null,
      limit: null,
      generalSearch: null,
      name: null,
      description: null,
      sort: null,
      sortBy: null,
    }
  ) => {
    const { page, limit, generalSearch, description, sortBy, sort, name } = params;

    try {
      const response = await axios.get(
        `category?page=${page ? page + 1 : ''}&limit=${limit ?? ''}&generalSearch=${
          generalSearch ?? ''
        }&name=${name ?? ''}&description=${description ?? ''}&sortBy=${sortBy ?? ''}&sort=${
          sort ?? ''
        }`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
