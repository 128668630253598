import { useEffect } from 'react';

import { Button } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { getInventoryItemsBySupplier, getSupplierById } from 'src/redux/slices/suppliers';

import ErrorContent from 'src/components/error-content';
import { useSettingsContext } from 'src/components/settings';
import { LeftIcon } from 'src/components/carousel/arrow-icons';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import SupplierDetails from '../supplier-details';
import { LoadingScreen } from '../../../components/loading-screen';
import { useAppDispatcher, useAppSelector } from '../../../redux/store';

type Props = {
  id: string;
};

export default function SupplierDetailView({ id }: Props) {
  const { supplier, status, error } = useAppSelector((state) => state.suppliers);

  const settings = useSettingsContext();
  const dispatch = useAppDispatcher();

  useEffect(() => {
    dispatch(getSupplierById({ supplierID: id }));
    dispatch(getInventoryItemsBySupplier({ supplierId: id }));
  }, [dispatch, id]);

  if (status === 'loading' || status === 'idle') {
    return <LoadingScreen />;
  }

  if (status === 'failure') {
    return (
      <ErrorContent
        filled
        title="Failed to load supplier details"
        description={error}
        action={
          <Button
            component={RouterLink}
            href={paths.dashboard.suppliers.root}
            variant="contained"
            startIcon={<LeftIcon />}
          >
            Back to Suppliers
          </Button>
        }
        sx={{
          py: 10,
        }}
      />
    );
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Supplier Details"
        links={[
          {
            name: 'Suppliers',
            href: paths.dashboard.suppliers.root,
          },
          { name: 'Supplier Details' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <SupplierDetails currentSupplier={supplier} />
    </Container>
  );
}
