import { Divider, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import { useSettingsContext } from 'src/components/settings';

import BarSummaryRange from './bar-summary-range';
import InventoryAnalysis from './inventory-analysis';
import MonthlyTotalSales from './monthly-total-sales';
import BarSummarySnapshot from './bar-summary-snapshot';
import DailyTotalPurchases from './daily-total-purchases';
import MonthlyTotalSalesByQuantity from './monthly-total-sales-by-quantity';

export default function OverviewAnalyticsView() {
  const settings = useSettingsContext();
  const { user: authUser } = useAuthContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Typography
        variant="h4"
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        Hi, Welcome back {authUser?.firstName} 👋
      </Typography>

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_INVENTORY_ITEM_AVAILABILITY,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) && <InventoryAnalysis />}

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.VIEW_OPERATIONAL_ANALYTICS,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) && (
        <>
          <BarSummaryRange />
          <Divider
            sx={{
              width: 1,
              my: 2.5,
              borderStyle: 'dashed',
            }}
          />
          <BarSummarySnapshot />
        </>
      )}

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.VIEW_ADVANCE_ANALYTICS,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) && (
        <>
          <Divider
            sx={{
              width: 1,
              my: 2.5,
              borderStyle: 'dashed',
            }}
          />

          <DailyTotalPurchases />
        </>
      )}

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.VIEW_ADVANCE_ANALYTICS,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) && (
        <>
          <Divider
            sx={{
              width: 1,
              my: 2.5,
              borderStyle: 'dashed',
            }}
          />

          <Grid container spacing={3} mt={5}>
            <Grid item xs={12} md={6}>
              <MonthlyTotalSales />
            </Grid>

            <Grid item xs={12} md={6}>
              <MonthlyTotalSalesByQuantity />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}
