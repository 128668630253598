import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { MaintenanceIllustration } from 'src/assets/illustrations';

export default function MaintenanceView() {
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Under Construction
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>
        We are currently working hard on this page!
      </Typography>

      <MaintenanceIllustration sx={{ my: 10, height: 240 }} />

      {/* <Button component={RouterLink} href="/" size="large" variant="contained">
        Go to Home
      </Button> */}
    </Stack>
  );
}
