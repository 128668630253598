import axios, { AxiosRequestConfig, HttpStatusCode } from 'axios';

import { DEFAULT_INTERNAL_SERVER_ERROR_MESSAGE } from 'src/config-global';

const axiosInstance = axios.create({
  baseURL: '/api/v1',
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response) {
      const { status, config } = error.response;

      if (status === HttpStatusCode.Unauthorized && config.url !== endpoints.auth.login) {
        window.location.href = '/auth/login';
      }

      if (status === HttpStatusCode.Forbidden) {
        window.location.href = '/403';
      }

      if (status === HttpStatusCode.BadRequest) {
        const { data } = error.response;

        return Promise.reject(new Error(data?.message));
      }

      if (status === HttpStatusCode.InternalServerError) {
        return Promise.reject(new Error(DEFAULT_INTERNAL_SERVER_ERROR_MESSAGE));
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/auth/profile',
    login: '/auth/login',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  user: {
    fcm: (userId: string) => `user/fcm-token/${userId}`,
  },
};
