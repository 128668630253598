import { useState } from 'react';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

type ICustomDatePickerProps = {
  value: Date | null;
  onChange: (value: Date | null) => void;
  label: string;
};

export default function CustomDatePicker({
  label,
  value,
  onChange,
  ...props
}: ICustomDatePickerProps & DatePickerProps<Date>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      {...props}
      slotProps={{
        textField: {
          sx: {
            minWidth: 200,
          },
          onClick: () => {
            setIsOpen(true);
          },
          ...props.slotProps?.textField,
        },
      }}
    />
  );
}
