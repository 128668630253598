import React, { ReactNode } from 'react';

import { ListItemText, Stack, Tooltip } from '@mui/material';

interface IDataPoint {
  primary?: ReactNode;
  secondary?: ReactNode;
  isLink?: boolean;
  linkText?: string;
}

function DatePoint({ primary, secondary, isLink, linkText }: IDataPoint) {
  return (
    <Stack>
      <ListItemText primary={primary} primaryTypographyProps={{ typography: 'body2' }} />

      <Tooltip title={isLink ? linkText : secondary} placement="bottom-start">
        <ListItemText
          secondary={secondary}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
            sx: {
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          }}
        />
      </Tooltip>
    </Stack>
  );
}

export default DatePoint;
