import convert, { Unit } from 'convert-units';

export default class ItemUnitConverter {
  public static makeConvert(value: number, convertFrom: Unit, convertTo: Unit): number {
    return convert(value).from(convertFrom).to(convertTo);
  }

  public static valueToUnit(value: string): Unit {
    switch (value) {
      case 'ml':
        return 'ml';
      case 'l':
        return 'l';
      case 'g':
        return 'g';
      case 'kg':
        return 'kg';
      default:
        throw Error('invalid unit given');
    }
  }
}
