import Button from '@mui/material/Button';
import { Link, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDateTime } from 'src/utils/format-time';
import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { ISupplierListItem } from 'src/types/supplier';

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: ISupplierListItem;
  onDeleteRow: VoidFunction;
};

export default function SupplierTableRow({ row, selected, onEditRow, onDeleteRow }: Props) {
  const { _id, supplierName, contactPerson, email, phoneNumber, updatedAt } = row;

  const { user: authUser } = useAuthContext();

  const confirm = useBoolean();

  const popover = usePopover();

  return (
    <>
      <Link
        component={RouterLink}
        href={paths.dashboard.suppliers.view(_id)}
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'contents',
        }}
      >
        <TableRow hover selected={selected}>
          <TableCell align="left">{supplierName}</TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap' }}>{contactPerson}</TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap' }}>{phoneNumber}</TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap' }}>{email}</TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
            {fDateTime(updatedAt)}
          </TableCell>

          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            <Link component={RouterLink} href={paths.dashboard.suppliers.view(_id)} mr={2}>
              <Button variant="soft" color="primary">
                View More
              </Button>
            </Link>

            {hasPermission(
              authUser,
              'any',
              AuthPermissions.UPDATE_SUPPLIER,
              AuthPermissions.DELETE_SUPPLIER,
              AuthPermissions.ADMIN_PERMISSION,
              AuthPermissions.SUPER_ADMIN_PERMISSION
            ) && (
              <Tooltip title="More options">
                <IconButton
                  color={popover.open ? 'inherit' : 'default'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    popover.onOpen(e);
                  }}
                >
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      </Link>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 180 }}
      >
        {hasPermission(
          authUser,
          'any',
          AuthPermissions.UPDATE_SUPPLIER,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        ) && (
          <MenuItem
            onClick={() => {
              onEditRow();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}

        {hasPermission(
          authUser,
          'any',
          AuthPermissions.DELETE_SUPPLIER,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        ) &&
          authUser?._id !== _id && (
            <MenuItem
              onClick={() => {
                confirm.onTrue();
                popover.onClose();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="solar:trash-bin-trash-bold" />
              Delete
            </MenuItem>
          )}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Warning : Permanent Deletion"
        content={`Are you sure you want to permanently delete ${supplierName}'s details from the system? This action cannot be undone.`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
              onDeleteRow();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
