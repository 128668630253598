import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import axiosInstance from 'src/utils/axios';

import { KITCHEN_CATEGORY_ID } from 'src/config-global';
import { getPaginatedUnits } from 'src/redux/slices/units';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  TCreateKitchenPurchaseOrder,
  TCreateKitchenPurchaseOrderItem,
} from 'src/redux/slices/kitchen-purchase-order';

import Iconify from 'src/components/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';

import { IUnitListItem } from 'src/types/unit';
import { IKitchenListItem } from 'src/types/kitchen';

interface IKitchenPurchaseNewItemRow {
  index: number;
  supplier: string | null | undefined;
  remove: (index: number) => void;
  isLast: boolean;
  isEdit: boolean;
  isCompleted: boolean;
}

export default function KitchenPurchaseNewItemRow({
  index,
  supplier,
  remove,
  isLast,
  isEdit,
  isCompleted,
}: IKitchenPurchaseNewItemRow) {
  const dispatch = useAppDispatcher();

  const { units } = useAppSelector((state) => state.units);

  const [inventorySearchQuery, setInventorySearchQuery] = useState('');

  const [unitFamily, setUnitFamily] = useState<string | null>(null);

  const [localInventory, setLocalInventory] = useState<IKitchenListItem[]>([]);

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<TCreateKitchenPurchaseOrder>();

  const defaultHelperText: string = errors.items?.[index] ? ' ' : '';

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPaginatedKitchenItems = useCallback(
    debounce(async (query) => {
      try {
        const response = await axiosInstance.get(
          `inventory/kitchen/item?page=&limit=&generalSearch=${query}&itemName=&itemCode=&minQuantity=&maxQuantity=&minPrice=&maxPrice=&suppliers=${
            supplier ?? ''
          }&categories=${KITCHEN_CATEGORY_ID}&units=&alertEnabled=&alertSent=&availability=&sortBy=itemName&sort=asc&group=&status=${
            isEdit ? '' : 'active'
          }&createdBy=`
        );

        setLocalInventory(response.data.data.items);
      } catch (err) {
        console.warn(err);
      }
    }, 500),
    [dispatch, supplier]
  );

  useEffect(() => {
    fetchPaginatedKitchenItems(inventorySearchQuery);

    return fetchPaginatedKitchenItems.cancel;
  }, [inventorySearchQuery, fetchPaginatedKitchenItems]);

  useEffect(() => {
    dispatch(getPaginatedUnits({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableRow>
      <TableCell>
        {index + 1 < 10 ? `0${index + 1}` : index + 1}

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>

      <TableCell
        sx={{
          minWidth: 300,
        }}
      >
        <Controller
          name={`items.${index}.inventoryItem`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              fullWidth
              size="small"
              disabled={isCompleted}
              multiple={false}
              options={localInventory.filter(
                (kitchenItem) =>
                  !watchItems
                    .map((watchItem: TCreateKitchenPurchaseOrderItem) => watchItem.inventoryItem)
                    .includes(kitchenItem._id)
              )}
              isOptionEqualToValue={(option, value) => {
                if (typeof option === 'string') {
                  return option === value;
                }

                if (option && '_id' in option) {
                  return option._id === value;
                }

                return false;
              }}
              getOptionLabel={(option: IKitchenListItem | string) => {
                if (!option) {
                  return '';
                }

                if (typeof option === 'string') {
                  if (option.length === 0) {
                    return option;
                  }

                  const value = localInventory.find(
                    (kitchenItem: IKitchenListItem) => kitchenItem._id === option
                  );
                  return value ? value.itemName : '';
                }

                if (option && 'itemName' in option) {
                  return option.itemName;
                }

                return '';
              }}
              renderOption={(props, option) => {
                if (typeof option === 'string') {
                  return (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  );
                }

                return (
                  <li {...props} key={option._id}>
                    {option.itemName}
                  </li>
                );
              }}
              onInputChange={(_, value) => {
                setInventorySearchQuery(value);
              }}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  required
                  helperText={error ? error.message : defaultHelperText}
                  {...params}
                />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  field.onChange(newValue);
                } else {
                  setValue(`items.${index}.unit`, newValue?.unit._id ?? '');
                  clearErrors(`items.${index}.unit`);
                  setUnitFamily(newValue?.unit.category ?? null);
                  field.onChange(newValue?._id);
                }
              }}
            />
          )}
        />
      </TableCell>

      <TableCell>
        <RHFTextField
          fullWidth
          required
          type="number"
          size="small"
          name={`items.${index}.quantity`}
          InputProps={{
            endAdornment: errors?.items?.[index]?.quantity ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.quantity?.message || 'Invalid quantity'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell>
        <RHFSelect
          fullWidth
          required
          size="small"
          name={`items.${index}.unit`}
          InputProps={{
            endAdornment: errors?.items?.[index]?.unit ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.unit?.message || 'Unit is required'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          showError={false}
          helperText={defaultHelperText}
        >
          {units.units
            .filter((unit: IUnitListItem) => (unitFamily ? unit.category === unitFamily : true))
            .map((unit: IUnitListItem) => (
              <MenuItem key={unit._id} value={unit._id}>
                {unit.abbreviation}
              </MenuItem>
            ))}
        </RHFSelect>
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          required
          type="number"
          name={`items.${index}.unitPrice`}
          placeholder="0.00"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.unitPrice ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.unitPrice?.message || 'Invalid Unit Price'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          type="number"
          name={`items.${index}.discount`}
          placeholder="0.00"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.discount ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.discount?.message || 'Invalid Discount'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          type="number"
          name={`items.${index}.totalAfterDiscount`}
          placeholder="0.00"
          onChange={(event) => {
            setValue(
              `items.${index}.unitPrice`,
              Number(event.target.value) / watchItems[index].quantity
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.totalAfterDiscount ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.totalAfterDiscount?.message || ''}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip title={isLast ? 'Last item cannot be deleted' : 'Delete'}>
          <span>
            <IconButton disabled={isLast} color="error" onClick={() => remove(index)}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </span>
        </Tooltip>

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>
    </TableRow>
  );
}
