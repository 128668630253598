import { useCallback, useEffect, useState } from 'react';
import { addMonths, endOfMonth, startOfMonth, startOfYear } from 'date-fns';

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { fAPIDate } from 'src/utils/format-time';
import { getMonthsOfCurrentYear } from 'src/utils/functions';

import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { IMonthlySalesItem } from 'src/redux/slices/monthly-sales';
import { getMonthlySalesByQuantities } from 'src/redux/slices/monthly-sales-quantities';

import Label from 'src/components/label';
import EmptyContent from 'src/components/empty-content';

import AnalyticsCurrentVisits from '../analytics-current-visits';

export default function MonthlyTotalSalesByQuantity() {
  const dispatch = useAppDispatcher();

  const {
    summary: { data },
  } = useAppSelector((state) => state.monthlySalesQuantities);

  const months = getMonthsOfCurrentYear();
  const currentMonthIndex = new Date().getMonth();
  const [month, setMonth] = useState<string>(months[currentMonthIndex]);

  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfMonth(new Date()));

  const handleFilterMonth = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedMonth = event.target.value;
      setMonth(selectedMonth);

      const monthIndex = months.indexOf(selectedMonth);
      const selectedDate = addMonths(startOfYear(new Date()), monthIndex);
      setStartDate(startOfMonth(selectedDate));
      setEndDate(endOfMonth(selectedDate));
    },
    [months]
  );

  const fetchGetPurchaseAnalysisBar = useCallback(() => {
    dispatch(
      getMonthlySalesByQuantities({
        startDate: fAPIDate(startDate),
        endDate: fAPIDate(endDate),
      })
    );
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    fetchGetPurchaseAnalysisBar();
  }, [fetchGetPurchaseAnalysisBar]);

  return (
    <Stack>
      <Typography variant="h5">Most Selling Products</Typography>
      <Stack mt={4}>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 180 },
            mb: 2,
          }}
        >
          <InputLabel>Month</InputLabel>

          <Select
            value={month}
            onChange={handleFilterMonth}
            input={<OutlinedInput label="Month" />}
            renderValue={(selected) => (
              <Label variant="soft" color="primary">
                {selected}
              </Label>
            )}
            sx={{ textTransform: 'capitalize' }}
          >
            {months.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {data.length > 0 ? (
          <AnalyticsCurrentVisits
            title=""
            chart={{
              series: data.map((item: IMonthlySalesItem) => ({
                label: item.itemName,
                value: item.totalQuantity,
              })),
            }}
          />
        ) : (
          <EmptyContent
            filled
            title="No Data Found"
            sx={{
              height: 460,
            }}
          />
        )}
      </Stack>
    </Stack>
  );
}
