import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import { BAR_ALERT_OPTIONS, IBarTableFilters, IBarTableFilterValue } from 'src/types/bar';

type Props = {
  filters: IBarTableFilters;
  onFilters: (name: string, value: IBarTableFilterValue) => void;
  handleResetFilters: VoidFunction;
  canReset: boolean;
};

const ALERT_STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...BAR_ALERT_OPTIONS];

export default function BarTableToolbar({
  filters,
  onFilters,
  handleResetFilters,
  canReset,
}: Props) {
  const [input, setInput] = useState<string>('');
  const [minQuantity, setMinQuantity] = useState<string>('');
  const [maxQuantity, setMaxQuantity] = useState<string>('');

  const [minPrice, setMinPrice] = useState<string>('');
  const [maxPrice, setMaxPrice] = useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      onFilters('generalSearch', value);
    }, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMinQuantity = useCallback(
    debounce((value) => {
      onFilters('minQuantity', value);
    }, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMaxQuantity = useCallback(
    debounce((value) => {
      onFilters('maxQuantity', value);
    }, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMinPrice = useCallback(
    debounce((value) => {
      onFilters('minPrice', value);
    }, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMaxPrice = useCallback(
    debounce((value) => {
      onFilters('maxPrice', value);
    }, 500),
    []
  );

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
      debouncedMinQuantity.cancel();
      debouncedMaxQuantity.cancel();
      debouncedMinPrice.cancel();
      debouncedMaxPrice.cancel();
    };
  }, [
    debouncedSearch,
    debouncedMinQuantity,
    debouncedMaxQuantity,
    debouncedMinPrice,
    debouncedMaxPrice,
  ]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInput(value);

    debouncedSearch(value);
  };

  const clearSearchText = () => {
    setInput('');

    debouncedSearch('');
  };

  const handleMinQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setMinQuantity(value);

    debouncedMinQuantity(value === '' ? null : value);
  };

  const handleMaxQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setMaxQuantity(value);
    debouncedMaxQuantity(value === '' ? null : value);
  };

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setMinPrice(value);

    debouncedMinPrice(value === '' ? null : value);
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setMaxPrice(value);
    debouncedMaxPrice(value === '' ? null : value);
  };

  const handleFilterAlerts = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters('alertsEnabled', event.target.value);
    },
    [onFilters]
  );

  const clearAll = useCallback(() => {
    setInput('');
    setMinPrice('');
    setMaxPrice('');

    setMinQuantity('');
    setMaxQuantity('');

    handleResetFilters();
  }, [handleResetFilters]);

  const hasQuantityError = Number(maxQuantity) < Number(minQuantity) && maxQuantity !== '';
  const hasPriceError = Number(maxPrice) < Number(minPrice) && maxPrice !== '';
  const hasNegativeMinPrice = Number(minPrice) < 0;
  const hasNegativeMaxPrice = Number(maxPrice) < 0;
  const hasNegativeMinQuantity = Number(minQuantity) < 0;
  const hasNegativeMaxQuantity = Number(maxQuantity) < 0;

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={input}
          onChange={handleSearchChange}
          placeholder="Search for item name, item code, barcode..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
            endAdornment:
              input.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearchText}>
                    <Iconify icon="eva:close-fill" color="text.disabled" />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          inputProps={{
            autoComplete: 'off',
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <TextField
          label="Min Price"
          value={minPrice}
          onChange={handleMinPriceChange}
          type="number"
          placeholder="Min Price"
          sx={{
            minWidth: 150,
          }}
          inputProps={{
            min: 0,
            autoComplete: 'off',
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          error={hasNegativeMinPrice}
          InputProps={{
            endAdornment: hasNegativeMinPrice ? (
              <InputAdornment position="end">
                <Tooltip title="Min price cannot be negative">
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
        />
        <TextField
          value={maxPrice}
          onChange={handleMaxPriceChange}
          type="number"
          placeholder="Max Price"
          label="Max Price"
          sx={{
            minWidth: 150,
          }}
          inputProps={{
            min: 0,
            autoComplete: 'off',
          }}
          error={hasPriceError || hasNegativeMaxPrice}
          InputProps={{
            endAdornment:
              hasPriceError || hasNegativeMaxPrice ? (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      hasNegativeMaxPrice
                        ? 'Max Price cannot be negative'
                        : 'Max Price should be greater than or equal to Min Price'
                    }
                  >
                    <Iconify icon="eva:info-outline" color="error.main" />
                  </Tooltip>
                </InputAdornment>
              ) : null,
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        />
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <TextField
          label="Min Quantity"
          value={minQuantity}
          onChange={handleMinQuantityChange}
          type="number"
          placeholder="Min Quantity"
          sx={{
            minWidth: 150,
          }}
          inputProps={{
            min: 0,
            autoComplete: 'off',
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          error={hasNegativeMinQuantity}
          InputProps={{
            endAdornment: hasNegativeMinQuantity ? (
              <InputAdornment position="end">
                <Tooltip title="Min quantity cannot be negative">
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
        />
        <TextField
          label="Max Quantity"
          value={maxQuantity}
          onChange={handleMaxQuantityChange}
          type="number"
          placeholder="Max Quantity"
          sx={{
            minWidth: 150,
          }}
          inputProps={{
            min: 0,
            autoComplete: 'off',
          }}
          error={hasQuantityError || hasNegativeMaxQuantity}
          InputProps={{
            endAdornment:
              hasQuantityError || hasNegativeMaxQuantity ? (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      hasNegativeMaxQuantity
                        ? 'Max Quantity cannot be negative'
                        : 'Max Quantity should be greater than or equal to Min Quantity'
                    }
                  >
                    <Iconify icon="eva:info-outline" color="error.main" />
                  </Tooltip>
                </InputAdornment>
              ) : null,
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        />
      </Stack>

      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 180 },
        }}
      >
        <InputLabel>Alerts</InputLabel>

        <Select
          multiple={false}
          value={[filters.alertsEnabled]}
          onChange={handleFilterAlerts}
          input={<OutlinedInput label="Alerts" />}
          renderValue={(selected) => (
            <Label variant="soft" color="primary">
              {selected}
            </Label>
          )}
          sx={{ textTransform: 'capitalize' }}
        >
          {ALERT_STATUS_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {canReset && (
        <Button
          color="error"
          onClick={clearAll}
          variant="text"
          sx={{ textDecoration: 'underline' }}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}
