import { AxiosError, HttpStatusCode } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { KITCHEN_CATEGORY_ID } from 'src/config-global';

import { ICategoryListItem } from 'src/types/category';
import { IKitchenHistory, IKitchenItem, IKitchenListItem } from 'src/types/kitchen';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  inventory: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    items: IKitchenListItem[];
  };
  item: IKitchenItem | null;
  transactions: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    transactions: {
      count: number;
      page: number;
      pageSize: number;
      pageCount: number;
      items: IKitchenHistory[];
    };
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  inventory: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    items: [],
  },
  item: null,
  transactions: {
    status: 'idle',
    error: undefined,
    transactions: {
      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      items: [],
    },
  },
};

const inventory = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    removeKitchenItem: (state, action) => {
      const { itemId } = action.payload;

      const index = state.inventory.items.findIndex((item) => item._id === itemId);

      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.inventory.items = state.inventory.items.filter((item) => item._id !== itemId);
      }
    },
    rollbackKitchenItemDeletion: (state, action) => {
      const { item, index } = action.payload;

      state.inventory.items.splice(index, 0, item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInventory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getInventory.fulfilled, (state, action) => {
        state.status = 'success';
        state.inventory = action.payload;
      })
      .addCase(getInventory.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getInventoryById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getInventoryById.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload;
      })
      .addCase(getInventoryById.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })

      .addCase(getKitchenTransactionsById.pending, (state) => {
        state.transactions.status = 'loading';
      })
      .addCase(getKitchenTransactionsById.fulfilled, (state, action) => {
        state.transactions.status = 'success';
        state.transactions.transactions = action.payload;
      })
      .addCase(getKitchenTransactionsById.rejected, (state, action) => {
        state.transactions.status = 'failure';
        state.transactions.error = action.error.message;
      });
  },
});

export default inventory.reducer;

export const { removeKitchenItem, rollbackKitchenItemDeletion } = inventory.actions;

export const getInventory = createAsyncThunk(
  'kitchen/getInventory',
  async (
    params: {
      page: number;
      limit: number;
      generalSearch?: string | null;
      itemName?: string | null;
      itemCode?: string | null;
      minQuantity?: number | null;
      maxQuantity?: number | null;
      minPrice?: number | null;
      maxPrice?: number | null;
      suppliers?: string[];
      supplierSeparator?: ',' | '|';
      units?: string[];
      unitsSeparator?: ',' | '|';
      alertSent?: string | null;
      availability?: string | null;
      alertEnabled?: boolean | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: 1,
      limit: 10,
      suppliers: [],
      supplierSeparator: ',',
      units: [],
      unitsSeparator: ',',
      generalSearch: null,
      alertEnabled: null,
      itemName: null,
      itemCode: null,
      maxPrice: null,
      maxQuantity: null,
      minPrice: null,
      minQuantity: null,
      alertSent: null,
      availability: null,
      sort: 'desc',
      sortBy: 'updatedAt',
    }
  ) => {
    const {
      page,
      limit,
      generalSearch,
      itemName,
      itemCode,
      minQuantity,
      maxQuantity,
      minPrice,
      maxPrice,
      suppliers,
      supplierSeparator,
      units,
      unitsSeparator,
      alertEnabled,
      alertSent,
      availability,
      sortBy,
      sort,
    } = params;

    try {
      const response = await axios.get(
        `inventory/kitchen/item?page=${page + 1}&limit=${limit}&generalSearch=${
          generalSearch ?? ''
        }&itemName=${itemName ?? ''}&itemCode=${itemCode ?? ''}&minQuantity=${
          minQuantity ?? ''
        }&maxQuantity=${maxQuantity ?? ''}&minPrice=${minPrice ?? ''}&maxPrice=${
          maxPrice ?? ''
        }&suppliers=${
          suppliers ? suppliers.join(supplierSeparator) : ''
        }&categories=${KITCHEN_CATEGORY_ID}&units=${
          units ? units.join(unitsSeparator) : ''
        }&alertEnabled=${alertEnabled ?? ''}&alertSent=${alertSent ?? ''}&availability=${
          availability ?? ''
        }&sortBy=${sortBy}&sort=${sort ?? ''}&group=&createdBy=&status=active`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getInventoryById = createAsyncThunk(
  'kitchen/getInventoryById',
  async (params: { itemID: string }) => {
    try {
      const { itemID } = params;
      const response = await axios.get(`inventory/kitchen/item/${itemID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getKitchenTransactionsById = createAsyncThunk(
  'kitchen/transactions/byId',
  async (params: {
    itemID: string;
    page: number;
    limit: number;
    sort: 'asc' | 'desc';
    sortBy: string;
  }) => {
    try {
      const { itemID, page, limit, sort, sortBy } = params;
      const response = await axios.get(
        `inventory/kitchen/transaction/item/history/${itemID}?page=${
          page + 1
        }&limit=${limit}&sortBy=${sortBy}&sort=${sort}`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteInventory = createAsyncThunk(
  'inventory/deleteInventory',
  async (params: { item: IKitchenListItem; index: number }, { dispatch }) => {
    const { item, index } = params;

    try {
      const response = await axios.delete(`inventory/kitchen/item/${item._id}`);
      return response.data.data;
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === HttpStatusCode.FailedDependency) {
        dispatch(rollbackKitchenItemDeletion({ item, index }));
        throw new AxiosError(err.response.data.message);
      }

      throw err;
    }
  }
);

export const disableKitchenItem = createAsyncThunk(
  'inventory/disableKitchenItem',
  async (params: { item: any; index: number }, { dispatch }) => {
    const { item, index } = params;
    try {
      const response = await axios.put(`inventory/kitchen/item/${item._id}`, {
        ...item,
        itemName: item.itemName,
        unit: item.unit._id,
        category: item.category.map((category: ICategoryListItem) => category._id),
        status: 'disabled',
      });
      return response.data.data;
    } catch (err) {
      dispatch(rollbackKitchenItemDeletion({ item, index }));
      throw err;
    }
  }
);

export interface TCreateKitchenItem
  extends Omit<
    IKitchenItem,
    | '_id'
    | 'itemCode'
    | 'createdAt'
    | 'updatedAt'
    | 'unit'
    | 'category'
    | 'suppliers'
    | 'updatedBy'
    | 'availability'
  > {
  unit: string;
  category: string[];
  suppliers: string[];
}

export const createInventory = createAsyncThunk(
  'kitchen/createKitchenItem',
  async (params: { newInventory: TCreateKitchenItem }) => {
    const { newInventory } = params;
    const response = await axios.post(`inventory/kitchen/item`, newInventory);
    return response.data.data;
  }
);

export const updateInventory = createAsyncThunk(
  'inventory/updateInventory',
  async (params: { inventoryId: string; updatingInventory: TCreateKitchenItem }) => {
    const { updatingInventory, inventoryId } = params;
    const response = await axios.put(`inventory/kitchen/item/${inventoryId}`, updatingInventory);
    return response.data.data;
  }
);
