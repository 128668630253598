import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { useTranslate } from 'src/locales';
import { APP_BRAND_NAME } from 'src/config-global';

interface Props extends DialogProps {
  onClose: (confirmed: boolean) => void;
}

export default function LogoutDialog({ onClose, ...other }: Props) {
  const { t } = useTranslate();

  return (
    <Dialog maxWidth="sm" onClose={onClose} {...other}>
      <DialogTitle data-test-id="test_logout_confirmation_title">
        {t('logout.confirmation.title', { APP_BRAND_NAME })}
      </DialogTitle>

      <DialogActions>
        <Button
          data-test-id="test_logout_confirmation_cancel_btn"
          color="inherit"
          variant="outlined"
          onClick={() => onClose(false)}
        >
          {t('logout.confirmation.cancel')}
        </Button>

        <Button
          data-test-id="test_logout_confirmation_confirm_btn"
          variant="contained"
          onClick={() => onClose(true)}
        >
          {t('logout.confirmation.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
