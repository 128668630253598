import { TRANSACTION_PAYMENT_STATUS, TRANSACTION_STATUS } from 'src/utils/common-types';

import { IUnitListItem } from './unit';
import { IKitchenListItem } from './kitchen';
import { ISupplierListItem } from './supplier';

export type IKitchenPurchaseOrdersTableFilterValue = string | string[] | boolean;

export type IKitchenPurchaseOrdersTableFilters = {
  generalSearch: string;
  paymentStatus: TRANSACTION_PAYMENT_STATUS | null;
  purchaseDate: Date | null;
};

export type IKitchenPurchaseOrdersTableSort = {
  sortBy: string;
  order: 'asc' | 'desc';
};

export const KITCHEN_PURCHASE_ORDERS_STATUS_OPTIONS = [
  { value: 'completed', label: 'Confirmed' },
  { value: 'draft', label: 'Draft' },
];

export const KITCHEN_PURCHASE_ORDERS_PAYMENT_STATUS_OPTIONS = [
  { value: TRANSACTION_PAYMENT_STATUS.PAID, label: 'Paid' },
  { value: TRANSACTION_PAYMENT_STATUS.PARTIALLY_PAID, label: 'Partially Paid' },
  { value: TRANSACTION_PAYMENT_STATUS.NOT_PAID, label: 'Not Paid' },
];

export enum KitchenPurchaseOrdersStatus {
  ALL = 'all',
  CONFIRMED = 'completed',
  DRAFT = 'draft',
}

export interface IKitchenPurchaseOrderListItem {
  _id: string;
  transactionCode: string;
  reason: string;
  description: string | null;
  supplier: ISupplierListItem | null;
  discount: number;
  isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  status: TRANSACTION_STATUS;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  updatedAt: string;
  purchaseOrDispatchDate: string;
}

export interface IKitchenPurchaseOrderChildItem {
  _id: string;
  inventoryItem: Pick<IKitchenListItem, '_id' | 'itemName' | 'itemCode'>;
  unit: IUnitListItem;
  quantity: number;
  unitPrice: number;
  discount: number;
  totalAfterDiscount: number;
}

export interface IKitchenPurchaseOrderDetailedItem {
  _id: string;
  transactionCode: string;
  reason: string;
  description: string | null;
  supplier: ISupplierListItem | null;
  discount: number;
  isPercentageDiscount: boolean;
  totalAfterDiscount: number;
  status: TRANSACTION_STATUS;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  updatedAt: string;
  purchaseOrDispatchDate: string;
  items: IKitchenPurchaseOrderChildItem[];
}
