import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { fCurrencyRupees } from 'src/utils/format-number';
import {
  TABLE_HEAD_CELL_TYPE,
  TRANSACTION_DISPATCH_TYPES,
  TRANSACTION_STATUS,
} from 'src/utils/common-types';

import { useAuthContext } from 'src/auth/hooks';
import { useAppDispatcher } from 'src/redux/store';
import { KITCHEN_CATEGORY_ID } from 'src/config-global';
import {
  createKitchenDispatchOrder,
  TCreateKitchenDispatchOrder,
  TCreateKitchenDispatchOrderItem,
  updateCompletedKitchenDispatchOrder,
  updateKitchenDispatchOrder,
} from 'src/redux/slices/kitchen-dispatch-order';

import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import CustomDatePicker from 'src/components/custom-datepicker/custom-datepicker';

import {
  IKitchenDispatchOrderDetailedItem,
  KitchenDispatchOrdersStatus,
} from 'src/types/kitchen-dispatches';

import { paths } from '../../routes/paths';
import KitchenDispatchNewItemRow from './kitchen-dispatch-new-item-row';

const LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'itemName',
    label: 'Item Name',
    align: 'left',
    primary: true,
  },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },
  { id: 'unit', label: 'Unit', align: 'left', primary: true, minWidth: 100 },
  { id: '', primary: true },
];

const LINE_ITEM_HEADERS_RETURN: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'itemName',
    label: 'Item Name',
    align: 'left',
    primary: true,
    minWidth: 300,
  },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },
  { id: 'unit', label: 'Unit', align: 'left', primary: true, minWidth: 100 },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    align: 'right',
    primary: true,
  },
  { id: 'discount', label: 'Discount', align: 'right', primary: true },
  { id: 'total', label: 'Total', align: 'right', primary: true },
  { id: '', primary: true },
];

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentKitchenDispatchItem?: IKitchenDispatchOrderDetailedItem;
};

export default function KitchenDispatchNewEditForm({ currentKitchenDispatchItem }: Props) {
  const dispatch = useAppDispatcher();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { user: authUser } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [removingLineItems, setRemovingLineItems] = useState<TCreateKitchenDispatchOrderItem[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);

  const NewKitchenDispatchOrderSchema = Yup.object<Shape<TCreateKitchenDispatchOrder>>().shape({
    category: Yup.string().required('Category is required'),
    reason: Yup.string().nullable(),
    description: Yup.string().nullable(),

    discount: Yup.number().when('transactionType', {
      is: TRANSACTION_DISPATCH_TYPES.RETURN,
      then: (schema) =>
        schema
          .typeError('Invalid discount')
          .min(0, 'Discount cannot be negative')
          .required('Discount is required'),
      otherwise: (schema) => schema.notRequired(),
    }),

    isPercentageDiscount: Yup.boolean().when('transactionType', {
      is: TRANSACTION_DISPATCH_TYPES.RETURN,
      then: (schema) => schema.required('Discount type is required'),
      otherwise: (schema) => schema.notRequired(),
    }),

    totalAfterDiscount: Yup.number().when('transactionType', {
      is: TRANSACTION_DISPATCH_TYPES.RETURN,
      then: (schema) =>
        schema
          .typeError('Invalid total')
          .moreThan(0, 'Total cannot be negative')
          .required('Total is required'),
      otherwise: (schema) => schema.notRequired(),
    }),

    transactionType: Yup.mixed<TRANSACTION_DISPATCH_TYPES>()
      .oneOf(Object.values(TRANSACTION_DISPATCH_TYPES))
      .required('Transaction type is required'),
    purchaseOrDispatchDate: Yup.date()
      .typeError('Invalid dispatch date')
      .required('Dispatch date is required'),
    status: Yup.mixed<TRANSACTION_STATUS>()
      .oneOf(Object.values(TRANSACTION_STATUS))
      .required('Status is required'),
    items: Yup.array()
      .of(
        Yup.object<Shape<TCreateKitchenDispatchOrderItem>>().shape({
          inventoryItem: Yup.string().required('Kitchen item is required'),
          quantity: Yup.number()
            .typeError('')
            .moreThan(0, 'Quantity cannot be negative')
            .required('Quantity is required'),
          unit: Yup.string().required('Unit is required'),
          unitPrice: Yup.number().when('transactionType', {
            is: TRANSACTION_DISPATCH_TYPES.RETURN,
            then: (schema) =>
              schema
                .typeError('Invalid unit price')
                .moreThan(0, 'Unit price cannot be negative')
                .required('Unit price is required'),
            otherwise: (schema) => schema.notRequired(),
          }),
          discount: Yup.number().when('transactionType', {
            is: TRANSACTION_DISPATCH_TYPES.RETURN,
            then: (schema) =>
              schema
                .typeError('Invalid discount')
                .min(0, 'Discount cannot be negative')
                .required('Discount is required'),
            otherwise: (schema) => schema.notRequired(),
          }),
          totalAfterDiscount: Yup.number().when('transactionType', {
            is: TRANSACTION_DISPATCH_TYPES.RETURN,
            then: (schema) =>
              schema
                .typeError('Invalid total price')
                .min(0, 'Total price cannot be negative')
                .required('Total price is required'),
            otherwise: (schema) => schema.notRequired(),
          }),
        })
      )
      .min(1, 'At least one item is required')
      .required('Items are required'),
  });

  const defaultItem = useMemo(
    () => ({
      inventoryItem: '',
      quantity: 0,
      unit: '',
      unitPrice: 0,
      discount: 0,
      isPercentageDiscount: false,
      totalAfterDiscount: 0,
    }),
    []
  );

  const defaultValues = useMemo<TCreateKitchenDispatchOrder>(
    () => ({
      category: KITCHEN_CATEGORY_ID,
      transactionType:
        currentKitchenDispatchItem?.transactionType ?? TRANSACTION_DISPATCH_TYPES.DISPATCH,
      description: null,
      reason: currentKitchenDispatchItem?.reason ?? null,
      status: currentKitchenDispatchItem?.status ?? TRANSACTION_STATUS.DRAFT,
      items: currentKitchenDispatchItem?.items.map((item) => ({
        ...item,
        inventoryItem: item.inventoryItem._id,
        unit: item.unit._id,
        unitPrice: item.unitPrice ?? 0,
        discount: item.discount ?? 0,
        totalAfterDiscount: item.totalAfterDiscount ?? 0,
      })) ?? [defaultItem],
      purchaseOrDispatchDate: currentKitchenDispatchItem?.purchaseOrDispatchDate
        ? new Date(currentKitchenDispatchItem?.purchaseOrDispatchDate)
        : new Date(),

      discount: currentKitchenDispatchItem?.discount ?? 0,
      isPercentageDiscount: currentKitchenDispatchItem?.isPercentageDiscount ?? false,
      totalAfterDiscount: currentKitchenDispatchItem?.totalAfterDiscount ?? 0,
    }),
    [defaultItem, currentKitchenDispatchItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewKitchenDispatchOrderSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    setValue,
  } = methods;

  console.log(errors);

  const transactionType = useWatch({
    control,
    name: 'transactionType',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  const watchDiscount = useWatch({
    control,
    name: 'discount',
  });

  const handleSubmitWithStatus = (status: TRANSACTION_STATUS) =>
    handleSubmit(async (data) => {
      const formData: TCreateKitchenDispatchOrder = {
        ...data,
        status,
        reason: data.reason ?? `${authUser?.firstName} created DO`,
        description: data.description ?? null,
        items:
          currentKitchenDispatchItem?.status === TRANSACTION_STATUS.COMPLETED
            ? [
                ...data.items.map((item) => ({
                  ...item,
                  description: null,
                })),
                ...removingLineItems.map((item) => ({
                  ...item,
                  quantity: 0,
                  unitPrice: 0,
                  discount: 0,
                  totalAfterDiscount: 0,
                })),
              ]
            : data.items.map((item) => ({
                ...item,
                description: null,
              })),
      };

      if (currentKitchenDispatchItem) {
        try {
          if (
            currentKitchenDispatchItem.status === TRANSACTION_STATUS.DRAFT &&
            status === TRANSACTION_STATUS.COMPLETED
          ) {
            await dispatch(
              updateKitchenDispatchOrder({
                dispatchOrderId: currentKitchenDispatchItem._id,
                dispatchTransaction: formData,
              })
            ).unwrap();
          } else if (status === TRANSACTION_STATUS.COMPLETED) {
            await dispatch(
              updateCompletedKitchenDispatchOrder({
                dispatchOrderId: currentKitchenDispatchItem._id,
                dispatchTransaction: formData,
              })
            ).unwrap();
          } else {
            await dispatch(
              updateKitchenDispatchOrder({
                dispatchOrderId: currentKitchenDispatchItem._id,
                dispatchTransaction: formData,
              })
            ).unwrap();
          }

          enqueueSnackbar('Kithen dispatch order update success');

          if (status === TRANSACTION_STATUS.COMPLETED) {
            navigate(paths.dashboard.kitchen.dispatch.root());
          } else {
            navigate(paths.dashboard.kitchen.dispatch.root(KitchenDispatchOrdersStatus.DRAFT));
          }

          reset();
        } catch (e) {
          if (typeof e === 'string') {
            enqueueSnackbar(e, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(e?.message, {
              variant: 'error',
            });
          }
        }
      } else {
        try {
          await dispatch(
            createKitchenDispatchOrder({
              dispatchTransaction: formData,
            })
          ).unwrap();

          enqueueSnackbar('Kithen dispatch order creation success');
          if (status === TRANSACTION_STATUS.COMPLETED) {
            navigate(paths.dashboard.kitchen.dispatch.root());
          } else {
            navigate(paths.dashboard.kitchen.dispatch.root(KitchenDispatchOrdersStatus.DRAFT));
          }
          reset();
        } catch (e) {
          if (typeof e === 'string') {
            enqueueSnackbar(e, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(e?.message, {
              variant: 'error',
            });
          }
        }
      }
    })();

  const scrollToBottom = () => {
    if (tableContainerRef.current) {
      const scrollContainer = tableContainerRef.current.querySelector('.simplebar-content-wrapper');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  const handleAppendItem = () => {
    append(defaultItem);
    // Use setTimeout to ensure DOM has updated before scrolling
    setTimeout(scrollToBottom, 100);
  };

  useEffect(() => {
    if (transactionType === TRANSACTION_DISPATCH_TYPES.RETURN) {
      let calculatedSubTotal = 0;

      watchItems.forEach((item, index) => {
        const calculatedTotal = (item.unitPrice ?? 0) * item.quantity - (item.discount ?? 0);

        calculatedSubTotal += calculatedTotal;

        if (calculatedTotal !== item.totalAfterDiscount) {
          setValue(`items.${index}.totalAfterDiscount`, calculatedTotal);
        }
      });

      setSubTotal(calculatedSubTotal);

      setValue('totalAfterDiscount', calculatedSubTotal - (watchDiscount ?? 0));
    }
  }, [watchItems, setValue, watchDiscount, transactionType]);

  useEffect(() => {
    if (transactionType === TRANSACTION_DISPATCH_TYPES.RETURN) {
      setValue('totalAfterDiscount', subTotal - (watchDiscount ?? 0));
    }
  }, [setValue, watchDiscount, subTotal, watch, transactionType]);

  const renderCreatePurchaseOrderButton = () => {
    if (
      currentKitchenDispatchItem?.status === TRANSACTION_STATUS.DRAFT ||
      !currentKitchenDispatchItem
    ) {
      return (
        <LoadingButton
          type="button"
          variant="outlined"
          loading={isSubmitting}
          onClick={() => handleSubmitWithStatus(TRANSACTION_STATUS.COMPLETED)}
        >
          {currentKitchenDispatchItem?.status === TRANSACTION_STATUS.DRAFT
            ? 'Mark as completed'
            : 'Create Dispatch Order'}
        </LoadingButton>
      );
    }
    return null;
  };

  const handleSubmitAction = () => {
    if (!currentKitchenDispatchItem) {
      handleSubmitWithStatus(TRANSACTION_STATUS.DRAFT);
      return;
    }

    if (currentKitchenDispatchItem?.status === TRANSACTION_STATUS.DRAFT) {
      handleSubmitWithStatus(TRANSACTION_STATUS.DRAFT);
      return;
    }

    handleSubmitWithStatus(TRANSACTION_STATUS.COMPLETED);
  };

  const getButtonText = () => {
    if (!currentKitchenDispatchItem) {
      return 'Save as Draft';
    }

    if (currentKitchenDispatchItem?.status === TRANSACTION_STATUS.DRAFT) {
      return 'Update Draft';
    }

    return 'Save Changes';
  };

  const isCompleted = currentKitchenDispatchItem?.status === TRANSACTION_STATUS.COMPLETED;

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Bill Information</Typography>
              </Grid>

              <Grid xs={12} md={4}>
                <Controller
                  name="purchaseOrDispatchDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomDatePicker
                      disableFuture
                      label="Dispatch Date"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid xs={12} md={4}>
                <RHFSelect fullWidth name="transactionType" label="Dispatch Status" required>
                  {Object.values(TRANSACTION_DISPATCH_TYPES).map((type) => (
                    <MenuItem
                      key={type}
                      value={type}
                      sx={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {type.split('_').join(' ')}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography variant="subtitle2">Line Items</Typography>
              </Grid>

              <Grid xs={12}>
                <Stack spacing={2}>
                  <TableContainer
                    ref={tableContainerRef}
                    sx={{ position: 'relative', overflow: 'hidden', height: '250px' }}
                  >
                    <Scrollbar>
                      <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                        <TableHeadCustom
                          headLabel={
                            transactionType === TRANSACTION_DISPATCH_TYPES.RETURN
                              ? LINE_ITEM_HEADERS_RETURN
                              : LINE_ITEM_HEADERS
                          }
                        />

                        <TableBody>
                          {fields.map((item, index) => (
                            <KitchenDispatchNewItemRow
                              key={item.id}
                              index={index}
                              remove={(itemIndex) => {
                                remove(itemIndex);

                                if (isCompleted) {
                                  setRemovingLineItems((prev) => [...prev, item]);
                                }
                              }}
                              isLast={fields.length === 1 && index === 0}
                              isEdit={!!currentKitchenDispatchItem}
                              isCompleted={isCompleted}
                              dispatchOrder={currentKitchenDispatchItem}
                              isReturnOrder={transactionType === TRANSACTION_DISPATCH_TYPES.RETURN}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                </Stack>

                {!isCompleted && (
                  <Stack mt={4}>
                    <Button variant="soft" color="primary" onClick={handleAppendItem}>
                      Add
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>

            {transactionType === TRANSACTION_DISPATCH_TYPES.RETURN && (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                mt={3}
                divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              >
                <Stack direction="row" gap={2}>
                  <Typography variant="subtitle2">Sub Total</Typography>
                  <Typography variant="subtitle2">{fCurrencyRupees(subTotal * 100)}</Typography>
                </Stack>

                <Stack direction="row" gap={2} alignItems="center">
                  <Typography variant="caption">Discount</Typography>
                  <RHFTextField
                    size="small"
                    name="discount"
                    type="number"
                    placeholder="0.00"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ typography: 'subtitle2', color: 'text.disabled' }}>Rs.</Box>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ maxWidth: { md: 150 } }}
                  />
                </Stack>

                <Stack direction="row" gap={2}>
                  <Typography variant="subtitle1">Grand Total</Typography>
                  <Typography variant="subtitle1">
                    {fCurrencyRupees((watch('totalAfterDiscount') ?? 0) * 100)}
                  </Typography>
                </Stack>
              </Stack>
            )}

            <Stack direction="row" justifyContent="space-between" gap={2} mt={5}>
              <Link component={RouterLink} href={paths.dashboard.kitchen.purchase.root()}>
                <Button variant="outlined" color="inherit">
                  Cancel
                </Button>
              </Link>

              <Stack direction="row" justifyContent="flex-end" gap={2}>
                {renderCreatePurchaseOrderButton()}

                <LoadingButton
                  type="button"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={handleSubmitAction}
                >
                  {getButtonText()}
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
