import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  TRANSACTION_DISPATCH_TYPES,
  TRANSACTION_PAYMENT_STATUS,
  TRANSACTION_STATUS,
} from 'src/utils/common-types';

import { BAR_CATEGORY_ID } from 'src/config-global';

import { IBarDispatchOrderDetailedItem, IBarDispatchOrderListItem } from 'src/types/bar-dispatches';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  transactions: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    transactions: IBarDispatchOrderListItem[];
  };
  item: IBarDispatchOrderListItem | null;
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  transactions: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    transactions: [],
  },
  item: null,
};

const barDispatchOrders = createSlice({
  name: 'barDispatchOrders',
  initialState,
  reducers: {
    removeBarDispatchOrderItem: (state, action) => {
      const { itemId } = action.payload;

      const index = state.transactions.transactions.findIndex((item) => item._id === itemId);

      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.transactions.transactions = state.transactions.transactions.filter(
          (item) => item._id !== itemId
        );
      }
    },
    rollbackBarDispatchOrderDeletion: (state, action) => {
      const { user: item, index } = action.payload;

      state.transactions.transactions.splice(index, 0, item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBarDispatchOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBarDispatchOrders.fulfilled, (state, action) => {
        state.status = 'success';
        state.transactions = action.payload;
      })
      .addCase(getBarDispatchOrders.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getBarDispatchOrderById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBarDispatchOrderById.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload;
      })
      .addCase(getBarDispatchOrderById.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default barDispatchOrders.reducer;

export const { removeBarDispatchOrderItem, rollbackBarDispatchOrderDeletion } =
  barDispatchOrders.actions;

export const getBarDispatchOrders = createAsyncThunk(
  'bar/dispatch/transactions',
  async (
    params: {
      page: number;
      limit: number;
      generalSearch?: string | null;
      status?: string | null;
      dispatchDate?: Date | null;
      dispatchType?: TRANSACTION_DISPATCH_TYPES | null;
      paymentStatus?: TRANSACTION_PAYMENT_STATUS | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: 1,
      limit: 10,
      generalSearch: null,
      dispatchType: null,
      paymentStatus: null,
      status: null,
      dispatchDate: null,
      sort: 'desc',
      sortBy: 'updatedAt',
    }
  ) => {
    const {
      page,
      limit,
      generalSearch,
      status,
      dispatchDate,
      dispatchType,
      paymentStatus,
      sortBy,
      sort,
    } = params;

    try {
      const response = await axios.get(
        `inventory/bar/transaction?page=${page + 1}&limit=${limit}&generalSearch=${
          generalSearch ?? ''
        }&transactionCode=&status=${status ?? ''}&transactionType=${
          dispatchType ?? 'dispatch|disposal|return'
        }&paymentStatus=${paymentStatus ?? ''}&purchaseOrDispatchDate=${
          dispatchDate ?? ''
        }&minTotalValue=&maxTotalValue=&isDiscounted=&suppliers=&categories=${BAR_CATEGORY_ID}&updatedBy=&sortBy=${
          sortBy ?? ''
        }&sort=${sort ?? ''}&createdBy=&customers=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getBarDispatchOrderById = createAsyncThunk(
  'bar/dispatch/transaction/id',
  async (params: { itemID: string }) => {
    try {
      const { itemID } = params;
      const response = await axios.get(`inventory/bar/transaction/${itemID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteBarDispatchOrder = createAsyncThunk(
  'bar/dispatch/transaction/delete',
  async (params: { item: IBarDispatchOrderListItem; index: number }, { dispatch }) => {
    const { item, index } = params;

    try {
      const response = await axios.delete(`inventory/bar/transaction/${item._id}`);
      return response.data.data;
    } catch (err) {
      dispatch(rollbackBarDispatchOrderDeletion({ item, index }));
      throw err;
    }
  }
);

export interface TCreateBarDispatchOrderItem {
  inventoryItem: string;
  quantity: number;
  unit: string;
  unitPrice: number;
  discount: number;
  totalAfterDiscount: number;
}

export interface TCreateBarDispatchOrder {
  category: string;
  reason: string | null;
  discount: number;
  totalAfterDiscount: number;
  transactionType: TRANSACTION_DISPATCH_TYPES;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  purchaseOrDispatchDate: Date;
  status: TRANSACTION_STATUS;
  items: TCreateBarDispatchOrderItem[];
}

export const createBarDispatchOrder = createAsyncThunk(
  'bar/dispatchOrder/create',
  async (params: { dispatchTransaction: TCreateBarDispatchOrder }) => {
    const { dispatchTransaction } = params;
    const response = await axios.post(`inventory/bar/transaction`, dispatchTransaction);
    return response.data.data;
  }
);

export const updateBarDispatchOrder = createAsyncThunk(
  'bar/purchaseOrder/update',
  async (params: { dispatchOrderId: string; dispatchTransaction: TCreateBarDispatchOrder }) => {
    const { dispatchTransaction, dispatchOrderId } = params;
    const response = await axios.put(
      `inventory/bar/transaction/${dispatchOrderId}`,
      dispatchTransaction
    );
    return response.data.data;
  }
);

export const updateCompletedBarDispatchOrder = createAsyncThunk(
  'bar/purchaseOrder/completed/update',
  async (params: { dispatchOrderId: string; dispatchTransaction: TCreateBarDispatchOrder }) => {
    const { dispatchTransaction, dispatchOrderId } = params;
    const response = await axios.put(
      `inventory/transaction/${dispatchOrderId}`,
      dispatchTransaction
    );
    return response.data.data;
  }
);

export const barDispatchOrderMarkAsCompleted = createAsyncThunk(
  'bar/dispatchOrder/marAsCompleted',
  async (params: { dispatchOrderId: string; dispatchOrder: IBarDispatchOrderDetailedItem }) => {
    const { dispatchOrderId, dispatchOrder } = params;

    const response = await axios.put(`/inventory/bar/transaction/${dispatchOrderId}`, {
      ...dispatchOrder,
      status: TRANSACTION_STATUS.COMPLETED,
      items: dispatchOrder.items.map((item) => ({
        ...item,
        inventoryItem: item.inventoryItem._id,
        unit: item.unit._id,
      })),
    });
    return response.data.data;
  }
);
