import { ApexOptions } from 'apexcharts';
import { useCallback, useEffect, useState } from 'react';
import { addMonths, endOfMonth, format, startOfMonth, startOfYear } from 'date-fns';

import {
  Box,
  Card,
  CardHeader,
  CardProps,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { fAPIDate } from 'src/utils/format-time';
import { fCurrencyRupees } from 'src/utils/format-number';
import { getMonthsOfCurrentYear } from 'src/utils/functions';

import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  getPurchaseAnalysisBar,
  getPurchaseAnalysisKitchen,
  getPurchaseAnalysisKitchenAndBar,
  IPurchaseAnalysisItem,
} from 'src/redux/slices/purchase-analysis';

import Label from 'src/components/label';
import Chart, { useChart } from 'src/components/chart';

interface Props extends CardProps {
  date: Date;
  title?: string;
  subheader?: string;
  chart: {
    labels: string[];
    colors?: string[];
    series: {
      name: string;
      type: string;
      fill?: string;
      data: number[];
    }[];
    options?: ApexOptions;
  };
}

function PurchaseChart({ date, title, subheader, chart, ...other }: Props) {
  const { labels, colors, series, options } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: '16%',
      },
    },
    fill: {
      type: series.map((i) => i.fill) as string[],
    },
    labels,
    xaxis: {
      type: 'category',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: number) => {
          if (typeof value !== 'undefined') {
            return `${fCurrencyRupees(value * 100)}`;
          }
          return value;
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }}>
        <Chart
          dir="ltr"
          type="line"
          series={series}
          options={chartOptions}
          width="100%"
          height={500}
        />
      </Box>
    </Card>
  );
}

export default function DailyTotalPurchases() {
  const dispatch = useAppDispatcher();

  const {
    summary: { kitchen, bar, all },
  } = useAppSelector((state) => state.purchaseAnalysis);

  const months = getMonthsOfCurrentYear();
  const currentMonthIndex = new Date().getMonth();
  const [month, setMonth] = useState<string>(months[currentMonthIndex]);

  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(endOfMonth(new Date()));

  const handleFilterMonth = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedMonth = event.target.value;
      setMonth(selectedMonth);

      const monthIndex = months.indexOf(selectedMonth);
      const selectedDate = addMonths(startOfYear(new Date()), monthIndex);
      setStartDate(startOfMonth(selectedDate));
      setEndDate(endOfMonth(selectedDate));
    },
    [months]
  );

  const fetchGetPurchaseAnalysisBar = useCallback(() => {
    dispatch(
      getPurchaseAnalysisBar({ startDate: fAPIDate(startDate), endDate: fAPIDate(endDate) })
    );
    dispatch(
      getPurchaseAnalysisKitchen({ startDate: fAPIDate(startDate), endDate: fAPIDate(endDate) })
    );
    dispatch(
      getPurchaseAnalysisKitchenAndBar({
        startDate: fAPIDate(startDate),
        endDate: fAPIDate(endDate),
      })
    );
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    fetchGetPurchaseAnalysisBar();
  }, [fetchGetPurchaseAnalysisBar]);

  return (
    <Stack>
      <Typography variant="h5">Daily Total Purchases</Typography>
      <Stack mt={4}>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 180 },
            mb: 2,
          }}
        >
          <InputLabel>Month</InputLabel>

          <Select
            value={month}
            onChange={handleFilterMonth}
            input={<OutlinedInput label="Month" />}
            renderValue={(selected) => (
              <Label variant="soft" color="primary">
                {selected}
              </Label>
            )}
            sx={{ textTransform: 'capitalize' }}
          >
            {months.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <PurchaseChart
          title=""
          subheader=""
          date={startDate}
          chart={{
            labels: kitchen.map((item: IPurchaseAnalysisItem) => format(new Date(item.date), 'dd')),
            series: [
              {
                name: 'Kitchen',
                type: 'area',
                fill: 'gradient',
                data: kitchen.map((item: IPurchaseAnalysisItem) => item.totalAmount),
              },
              {
                name: 'Bar',
                type: 'area',
                fill: 'gradient',
                data: bar.map((item: IPurchaseAnalysisItem) => item.totalAmount),
              },
              {
                name: 'All',
                type: 'area',
                fill: 'gradient',
                data: all.map((item: IPurchaseAnalysisItem) => item.totalAmount),
              },
            ],
            options: {
              xaxis: {
                labels: {
                  rotateAlways: true,
                },
              },
              responsive: [
                {
                  breakpoint: 768,
                  options: {
                    xaxis: {
                      labels: {
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                      },
                    },
                  },
                },
              ],
            },
          }}
        />
      </Stack>
    </Stack>
  );
}
