import { Navigate } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import { BarCreateView } from 'src/sections/bar/view';

export default function BarItemCreatePage() {
  const { user: authUser } = useAuthContext();

  return (
    <>
      <Helmet>
        <title> Create a bar item</title>
      </Helmet>

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.CREATE_BAR_ITEM,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) ? (
        <BarCreateView />
      ) : (
        <Navigate to="/403" />
      )}
    </>
  );
}
