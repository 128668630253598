import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

export interface IPermission {
  _id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  category: string;
  checked: boolean;
}

export interface IGroupedPermissions {
  name: string;
  description: string;
  permissions: IPermission[];
  checked: boolean;
}

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  permissions: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    groupedPermissions: IGroupedPermissions[];
  };
  list: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    permissions: IPermission[];
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  permissions: {
    count: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    groupedPermissions: [],
  },
  list: {
    count: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    permissions: [],
  },
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedPermissions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaginatedPermissions.fulfilled, (state, action) => {
        state.status = 'success';
        state.permissions = action.payload;
      })
      .addCase(getPaginatedPermissions.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })

      .addCase(getPaginatedPermissionsAsList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaginatedPermissionsAsList.fulfilled, (state, action) => {
        state.status = 'success';
        state.list = action.payload;
      })
      .addCase(getPaginatedPermissionsAsList.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default permissionsSlice.reducer;

export const getPaginatedPermissions = createAsyncThunk('permission', async () => {
  try {
    const response = await axios.get(
      `permission?page=&limit=&name=&generalSearch=&description=&sortBy=&sort=&categories=`
    );

    const { count, page, pageSize, pageCount, permissions } = response.data.data;

    const groupedPermissions = groupPermissionsByCategory(permissions);

    return {
      count,
      page,
      pageSize,
      pageCount,
      groupedPermissions,
    };
  } catch (err) {
    console.log(err);
    throw err;
  }
});

export const getPaginatedPermissionsAsList = createAsyncThunk('permission/list', async () => {
  try {
    const response = await axios.get(
      `permission?page=&limit=&name=&generalSearch=&description=&sortBy=&sort=&categories=`
    );

    return response.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
});

export const groupPermissionsByCategory = (permissions: IPermission[]): IGroupedPermissions[] => {
  const grouped: Record<string, IPermission[]> = {};

  permissions.forEach((permission) => {
    const { category } = permission;
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push({ ...permission, checked: false });
  });

  return Object.keys(grouped).map((category) => ({
    name: category,
    permissions: grouped[category],
    checked: false,
    description: 'Apply all above permissions',
  }));
};
