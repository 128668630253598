import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import axiosInstance from 'src/utils/axios';

import { useAppDispatcher } from 'src/redux/store';
import { BAR_CATEGORY_ID } from 'src/config-global';
import {
  TCreateBarPurchaseOrder,
  TCreateBarPurchaseOrderItem,
} from 'src/redux/slices/bar-purchase-order';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';

import { IBarListItem } from 'src/types/bar';

interface IBarPurchaseNewItemRow {
  index: number;
  supplier: string;
  remove: (index: number) => void;
  isLast: boolean;
  isEdit: boolean;
  isCompleted: boolean;
}

export default function BarPurchaseNewItemRow({
  index,
  supplier,
  remove,
  isLast,
  isEdit,
  isCompleted,
}: IBarPurchaseNewItemRow) {
  const dispatch = useAppDispatcher();

  const [inventorySearchQuery, setInventorySearchQuery] = useState('');

  const [localInventory, setLocalInventory] = useState<IBarListItem[]>([]);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<TCreateBarPurchaseOrder>();

  const defaultHelperText: string = errors.items?.[index] ? ' ' : '';

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPaginatedBarItems = useCallback(
    debounce(async (query) => {
      try {
        const response = await axiosInstance.get(
          `inventory/bar/item?page=&limit=&generalSearch=${query}&itemName=&itemCode=&minQuantity=&maxQuantity=&minPrice=&maxPrice=&suppliers=${
            supplier ?? ''
          }&categories=${BAR_CATEGORY_ID}&units=&alertEnabled=&alertSent=&availability=&sortBy=itemName&sort=asc&group=&status=${
            isEdit ? '' : 'active'
          }&createdBy=`
        );

        setLocalInventory(response.data.data.items);
      } catch (err) {
        console.warn(err);
      }
    }, 500),
    [dispatch, supplier]
  );

  useEffect(() => {
    fetchPaginatedBarItems(inventorySearchQuery);

    return fetchPaginatedBarItems.cancel;
  }, [inventorySearchQuery, fetchPaginatedBarItems]);

  return (
    <TableRow>
      <TableCell>
        {index + 1 < 10 ? `0${index + 1}` : index + 1}

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>

      <TableCell
        sx={{
          minWidth: 300,
        }}
      >
        <Controller
          name={`items.${index}.inventoryItem`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              fullWidth
              size="small"
              disabled={isCompleted}
              multiple={false}
              options={localInventory.filter(
                (barItem) =>
                  !watchItems
                    .map((watchItem: TCreateBarPurchaseOrderItem) => watchItem.inventoryItem)
                    .includes(barItem._id)
              )}
              isOptionEqualToValue={(option, value) => {
                if (typeof option === 'string') {
                  return option === value;
                }

                if (option && '_id' in option) {
                  return option._id === value;
                }

                return false;
              }}
              getOptionLabel={(option: IBarListItem | string) => {
                if (!option) {
                  return '';
                }

                if (typeof option === 'string') {
                  if (option.length === 0) {
                    return option;
                  }

                  const value = localInventory.find(
                    (kitchenItem: IBarListItem) => kitchenItem._id === option
                  );
                  return value ? value.itemName : '';
                }

                if (option && 'itemName' in option) {
                  return option.itemName;
                }

                return '';
              }}
              renderOption={(props, option) => {
                if (typeof option === 'string') {
                  return (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  );
                }

                return (
                  <li {...props} key={option._id}>
                    {option.itemName}
                  </li>
                );
              }}
              onInputChange={(_, value) => {
                setInventorySearchQuery(value);
              }}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  helperText={error ? error.message : defaultHelperText}
                  {...params}
                />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  field.onChange(newValue);
                } else {
                  field.onChange(newValue?._id);
                }
              }}
            />
          )}
        />
      </TableCell>

      <TableCell>
        <RHFTextField
          fullWidth
          size="small"
          name={`items.${index}.quantity`}
          InputProps={{
            endAdornment: errors?.items?.[index]?.quantity ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.quantity?.message || 'Invalid quantity'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          type="number"
          name={`items.${index}.unitPrice`}
          placeholder="0.00"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.unitPrice ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.unitPrice?.message || 'Invalid Unit Price'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          type="number"
          name={`items.${index}.discount`}
          placeholder="0.00"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.discount ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.discount?.message || 'Invalid Discount'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          type="number"
          name={`items.${index}.totalAfterDiscount`}
          placeholder="0.00"
          onChange={(event) => {
            setValue(
              `items.${index}.unitPrice`,
              Number(event.target.value) / watchItems[index].quantity
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.totalAfterDiscount ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.totalAfterDiscount?.message || ''}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell>
        <Tooltip title={isLast ? 'Last item cannot be deleted' : 'Delete'}>
          <span>
            <IconButton disabled={isLast} color="error" onClick={() => remove(index)}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </span>
        </Tooltip>

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>
    </TableRow>
  );
}
