import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TRANSACTION_DISPATCH_TYPES, TRANSACTION_STATUS } from 'src/utils/common-types';

import { KITCHEN_CATEGORY_ID } from 'src/config-global';

import {
  IKitchenDispatchOrderDetailedItem,
  IKitchenDispatchOrderListItem,
} from 'src/types/kitchen-dispatches';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  transactions: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    transactions: IKitchenDispatchOrderListItem[];
  };
  item: IKitchenDispatchOrderDetailedItem | null;
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  transactions: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    transactions: [],
  },
  item: null,
};

const kitchenDispatchOrders = createSlice({
  name: 'kitchenDispatchOrders',
  initialState,
  reducers: {
    removeKitchenDispatchOrderItem: (state, action) => {
      const { itemId } = action.payload;

      const index = state.transactions.transactions.findIndex((item) => item._id === itemId);

      if (index > -1) {
        action.payload.index = index; // Store the index for rollback
        state.transactions.transactions = state.transactions.transactions.filter(
          (item) => item._id !== itemId
        );
      }
    },
    rollbackKitchenDispatchOrderDeletion: (state, action) => {
      const { user: item, index } = action.payload;

      state.transactions.transactions.splice(index, 0, item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKitchenDispatchOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getKitchenDispatchOrders.fulfilled, (state, action) => {
        state.status = 'success';
        state.transactions = action.payload;
      })
      .addCase(getKitchenDispatchOrders.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      })
      .addCase(getKitchenDispatchOrderById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getKitchenDispatchOrderById.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload;
      })
      .addCase(getKitchenDispatchOrderById.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default kitchenDispatchOrders.reducer;

export const { removeKitchenDispatchOrderItem, rollbackKitchenDispatchOrderDeletion } =
  kitchenDispatchOrders.actions;

export const getKitchenDispatchOrders = createAsyncThunk(
  'kitchen/dispatch/transactions',
  async (
    params: {
      page: number;
      limit: number;
      generalSearch?: string | null;
      status?: string | null;
      dispatchDate?: Date | null;
      dispatchType: TRANSACTION_DISPATCH_TYPES | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: 1,
      limit: 10,
      generalSearch: null,
      status: null,
      dispatchDate: null,
      dispatchType: null,
      sort: 'desc',
      sortBy: 'updatedAt',
    }
  ) => {
    const { page, limit, generalSearch, status, dispatchDate, dispatchType, sortBy, sort } = params;

    try {
      const response = await axios.get(
        `inventory/kitchen/transaction?page=${page + 1}&limit=${limit}&generalSearch=${
          generalSearch ?? ''
        }&transactionCode=&status=${status ?? ''}&transactionType=${
          dispatchType ?? 'dispatch|disposal|return'
        }&paymentStatus=&purchaseOrDispatchDate=${
          dispatchDate ?? ''
        }&minTotalValue=&maxTotalValue=&isDiscounted=&suppliers=&categories=${KITCHEN_CATEGORY_ID}&updatedBy=&sortBy=${
          sortBy ?? ''
        }&sort=${sort ?? ''}&createdBy=&customers=`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getKitchenDispatchOrderById = createAsyncThunk(
  'kitchen/dispatch/transaction/id',
  async (params: { itemID: string }) => {
    try {
      const { itemID } = params;
      const response = await axios.get(`inventory/kitchen/transaction/${itemID}`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const deleteKitchenDispatchOrder = createAsyncThunk(
  'kitchen/dispatch/transaction/delete',
  async (params: { item: IKitchenDispatchOrderListItem; index: number }, { dispatch }) => {
    const { item, index } = params;

    try {
      const response = await axios.delete(`inventory/kitchen/transaction/${item._id}`);
      return response.data.data;
    } catch (err) {
      dispatch(rollbackKitchenDispatchOrderDeletion({ item, index }));
      throw err;
    }
  }
);

export interface TCreateKitchenDispatchOrderItem {
  inventoryItem: string;
  quantity: number;
  unit: string;
  unitPrice?: number;
  discount?: number;
  totalAfterDiscount?: number;
}

export interface TCreateKitchenDispatchOrder {
  category: string;
  reason: string | null;
  description: string | null;
  transactionType: TRANSACTION_DISPATCH_TYPES;
  purchaseOrDispatchDate: Date;
  status: TRANSACTION_STATUS;
  items: TCreateKitchenDispatchOrderItem[];

  discount?: number;
  isPercentageDiscount?: boolean;
  totalAfterDiscount?: number;
}

export const createKitchenDispatchOrder = createAsyncThunk(
  'kitchen/purchaseOrder/create',
  async (params: { dispatchTransaction: TCreateKitchenDispatchOrder }) => {
    const { dispatchTransaction } = params;
    const response = await axios.post(`inventory/kitchen/transaction`, dispatchTransaction);
    return response.data.data;
  }
);

export const updateKitchenDispatchOrder = createAsyncThunk(
  'kitchen/purchaseOrder/update',
  async (params: { dispatchOrderId: string; dispatchTransaction: TCreateKitchenDispatchOrder }) => {
    const { dispatchTransaction, dispatchOrderId } = params;
    const response = await axios.put(
      `inventory/kitchen/transaction/${dispatchOrderId}`,
      dispatchTransaction
    );
    return response.data.data;
  }
);

export const updateCompletedKitchenDispatchOrder = createAsyncThunk(
  'kitchen/purchaseOrder/completed/update',
  async (params: { dispatchOrderId: string; dispatchTransaction: TCreateKitchenDispatchOrder }) => {
    const { dispatchTransaction, dispatchOrderId } = params;
    const response = await axios.put(
      `inventory/transaction/${dispatchOrderId}`,
      dispatchTransaction
    );
    return response.data.data;
  }
);

export const kitchenDispatchOrderMarkAsCompleted = createAsyncThunk(
  'kitchen/dispatchOrder/marAsCompleted',
  async (params: { dispatchOrderId: string; dispatchOrder: IKitchenDispatchOrderDetailedItem }) => {
    const { dispatchOrderId, dispatchOrder } = params;

    const response = await axios.put(`/inventory/kitchen/transaction/${dispatchOrderId}`, {
      ...dispatchOrder,
      status: TRANSACTION_STATUS.COMPLETED,
      items: dispatchOrder.items.map((item) => ({
        ...item,
        inventoryItem: item.inventoryItem._id,
        unit: item.unit._id,
      })),
    });
    return response.data.data;
  }
);
