import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { getToken, MessagePayload, onMessage } from 'firebase/messaging';

import { Button } from '@mui/material';

import { paths } from 'src/routes/paths';

import { messaging } from 'src/firebase';
import { useAuthContext } from 'src/auth/hooks';
import { useAppDispatcher } from 'src/redux/store';
import { updateFCMToken } from 'src/redux/slices/users';
import {
  BAR_CATEGORY_ID,
  FIREBASE_CLOUD_MESSAGING_VAPID_KEY,
  KITCHEN_CATEGORY_ID,
} from 'src/config-global';

import { KitchenStatus } from 'src/types/kitchen';

export default function FCMNotification() {
  const dispatch = useAppDispatcher();
  const { authenticated, setNotificationToken } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    const requestPermission = async () => {
      try {
        if (messaging) {
          const token = await getToken(messaging, {
            vapidKey: FIREBASE_CLOUD_MESSAGING_VAPID_KEY,
          });

          if (token) {
            // axios.post()
            console.log(token, ' generated');
            // TODO: send this token to the server

            try {
              await dispatch(
                updateFCMToken({
                  fcmToken: token,
                })
              ).unwrap();

              setNotificationToken(token);
            } catch (e) {
              // if (typeof e === 'string') {
              //   enqueueSnackbar(e, {
              //     variant: 'error',
              //   });
              // } else {
              //   enqueueSnackbar(e?.message, {
              //     variant: 'error',
              //   });
              // }
            }
          } else {
            console.warn('failed to generate token');
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (authenticated) {
      requestPermission();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    if (!messaging) {
      console.warn('Firebase Messaging is not initialized.');
      return () => {};
    }

    const unsubscribe = onMessage(messaging, (payload: MessagePayload) => {
      if (payload.data) {
        try {
          enqueueSnackbar(`${payload.notification?.body}`, {
            autoHideDuration: 10000,
            variant: payload.data.availability === KitchenStatus.RUNNING_LOW ? 'warning' : 'error',
            action: (key) => (
              <Button
                variant="soft"
                type="button"
                onClick={() => {
                  closeSnackbar(key);

                  if (payload.data && payload.data.category === KITCHEN_CATEGORY_ID) {
                    navigate(paths.dashboard.kitchen.root(payload.data.availability));
                  } else if (payload.data && payload.data.category === BAR_CATEGORY_ID) {
                    navigate(paths.dashboard.bar.root(payload.data.availability));
                  }
                }}
              >
                View More
              </Button>
            ),
          });
        } catch (e) {
          console.log(e);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return <span />;
}
