import { Helmet } from 'react-helmet-async';
import { Navigate, useParams } from 'react-router';

import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import BarDispatchOrderDetailView from 'src/sections/bar-dispatches/view/bar-dispatch-order-detail-view';

export default function BarDispatchOrderDetailPage() {
  const params = useParams();

  const { user: authUser } = useAuthContext();

  const { id } = params;

  return (
    <>
      <Helmet>
        <title> Bar dispatch order details</title>
      </Helmet>

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_BAR_INVENTORY_TRANSACTION,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) ? (
        <BarDispatchOrderDetailView id={`${id}`} />
      ) : (
        <Navigate to="/403" />
      )}
    </>
  );
}
