import * as Yup from 'yup';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { KITCHEN_CATEGORY_ID } from 'src/config-global';
import { getPaginatedUnits } from 'src/redux/slices/units';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { getPaginatedSuppliers } from 'src/redux/slices/suppliers';
import { createInventory, TCreateKitchenItem, updateInventory } from 'src/redux/slices/kitchen';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { IUnitListItem } from 'src/types/unit';
import { IKitchenItem } from 'src/types/kitchen';
import { ISupplierListItem } from 'src/types/supplier';

import { paths } from '../../routes/paths';

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  currentKitchenItem?: IKitchenItem;
};

export default function KitchenNewEditForm({ currentKitchenItem }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatcher();

  const { suppliers, status } = useAppSelector((state) => state.suppliers);

  const [searchQuery, setSearchQuery] = useState('');

  const [selectedSuppliers, setSelectedSuppliers] = useState<ISupplierListItem[]>(
    currentKitchenItem?.suppliers ?? []
  );
  const [localSuppliers, setLocalSuppliers] = useState<ISupplierListItem[]>([]);

  const { units } = useAppSelector((state) => state.units);

  const NewKitchenItemSchema = Yup.object<Shape<TCreateKitchenItem>>().shape({
    itemName: Yup.string().required('Item name is required'),
    quantity: Yup.number().required('Quantity is required'),
    unit: Yup.string().required('Item unit is required'),
    category: Yup.array(Yup.string().required('Item category is required')).min(1).default([]),
    reorderThreshold: Yup.number().required('Re-order threshold is required'),
    alertEnabled: Yup.boolean().optional().default(false),
    suppliers: Yup.array(Yup.string().required('Supplier ID is required')).default([]),
  });

  const defaultValues = useMemo(
    () => ({
      itemName: currentKitchenItem?.itemName || '',
      quantity: currentKitchenItem?.quantity || 0,
      unit: currentKitchenItem?.unit._id || '',
      category: currentKitchenItem?.category.map((category) => category._id) || [
        KITCHEN_CATEGORY_ID,
      ],
      reorderThreshold: currentKitchenItem?.reorderThreshold || 0,
      alertEnabled: currentKitchenItem?.alertEnabled || false,
      suppliers: currentKitchenItem?.suppliers.map((supplier) => supplier._id) || [],
    }),
    [currentKitchenItem]
  );

  const methods = useForm({
    resolver: yupResolver(NewKitchenItemSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (currentKitchenItem) {
      // update
      try {
        await dispatch(
          updateInventory({
            inventoryId: currentKitchenItem._id,
            updatingInventory: {
              ...data,
              itemName: data.itemName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              suppliers: selectedSuppliers.map((supplier) => supplier._id),
            },
          })
        ).unwrap();

        enqueueSnackbar('Kitchen item edit success');
        navigate(paths.dashboard.kitchen.root());
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    } else {
      try {
        await dispatch(
          createInventory({
            newInventory: {
              ...data,
              itemName: data.itemName.trimStart().replace(/^\w/, (c) => c.toUpperCase()),
              suppliers: selectedSuppliers.map((supplier) => supplier._id),
            },
          })
        ).unwrap();

        enqueueSnackbar('Kitchen item creation success');
        navigate(paths.dashboard.kitchen.root());
        reset();
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPaginatedSuppliers = useCallback(
    debounce(async (query) => {
      dispatch(
        getPaginatedSuppliers({
          page: null,
          limit: null,
          generalSearch: query,
          sort: 'asc',
          sortBy: 'supplierName',
          categories: [KITCHEN_CATEGORY_ID],
        })
      );
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    fetchPaginatedSuppliers(searchQuery);

    return fetchPaginatedSuppliers.cancel;
  }, [searchQuery, fetchPaginatedSuppliers]);

  useEffect(() => {
    setLocalSuppliers(suppliers.suppliers);
  }, [suppliers]);

  useEffect(() => {
    dispatch(getPaginatedUnits({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid
                mb={1}
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                <Typography variant="subtitle2">Basic Information</Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField required name="itemName" label="Item name" />
              </Grid>

              <Grid xs={12} md={6}>
                <Controller
                  name="unit"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      required
                      sx={{
                        flexShrink: 0,
                        width: { xs: 1 },
                      }}
                    >
                      <InputLabel>Unit</InputLabel>

                      <Select
                        {...field}
                        multiple={false}
                        input={<OutlinedInput label="Unit" />}
                        MenuProps={{
                          PaperProps: {
                            sx: { maxHeight: 240 },
                          },
                        }}
                      >
                        {units.units.map((option: IUnitListItem) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.abbreviation}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2.5,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography mt={2} variant="subtitle2">
                  Additional Information
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <RHFTextField name="reorderThreshold" label="Re-order Threshold" type="number" />
              </Grid>

              <Grid xs={12} md={6}>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Controller
                      name="alertEnabled"
                      control={control}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Stock Alerts Enabled
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Receive stock alerts when stock is low or running low
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                />
              </Grid>

              <Divider
                sx={{
                  width: 1,
                  my: 2.5,
                  borderStyle: 'dashed',
                }}
              />

              <Grid mb={1} xs={12} width="full">
                <Typography mt={2} variant="subtitle2">
                  Supplier Information
                </Typography>
              </Grid>

              <Grid xs={12}>
                <Autocomplete
                  multiple
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.supplierName
                  }
                  filterOptions={(x) => x}
                  options={localSuppliers}
                  autoComplete
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  filterSelectedOptions
                  value={selectedSuppliers}
                  loading={status === 'loading'}
                  loadingText="Loading..."
                  noOptionsText="No Suppliers found..."
                  onChange={(event: any, newValue: ISupplierListItem[] | null) => {
                    const uniqueSuppliers = Array.from(
                      new Set([...(newValue || []), ...localSuppliers])
                    );
                    setLocalSuppliers(uniqueSuppliers);

                    setSelectedSuppliers(newValue ?? []);
                  }}
                  onInputChange={(event, newInputValue) => {
                    console.log(newInputValue);

                    setSearchQuery(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Search for suppliers..." fullWidth />
                  )}
                  renderOption={(props, option) => (
                    <li key={option._id} {...props}>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {option.supplierName}
                      </Typography>
                    </li>
                  )}
                />
              </Grid>
            </Grid>

            <Stack direction="row" justifyContent="flex-end" gap={2} mt={4}>
              <Link component={RouterLink} href={paths.dashboard.kitchen.root()}>
                <Button variant="outlined" color="inherit">
                  Cancel
                </Button>
              </Link>

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentKitchenItem ? 'Create Kitchen Item' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
