export enum TRANSACTION_TYPE {
  PURCHASE = 'purchase',
  DISPATCH = 'dispatch',
  DISPOSAL = 'disposal',
}

export enum TRANSACTION_DISPATCH_TYPES {
  DISPATCH = 'dispatch',
  DISPOSAL = 'disposal',
  RETURN = 'return',
}

export enum TRANSACTION_PAYMENT_STATUS {
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  NOT_PAID = 'not_paid',
  // REFUND = 'refund',
}

export enum TRANSACTION_STATUS {
  DRAFT = 'draft',
  COMPLETED = 'completed',
}

export interface TABLE_HEAD_CELL_TYPE {
  id: string;
  label?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  primary: boolean;
  span?: number;
  width?: number;
  minWidth?: number;
}

export const TAB_QUERY = 'activeTab';

export enum InventoryItemGroup {
  MALT_LIQUOR = 'Malt Liquor',
  SPARKLING_WINES = 'Sparkling Wines',
  OTHER_WINES = 'Other Wines',
  WHISKY = 'Whisky',
  BRANDY = 'Brandy',
  RUM = 'Rum',
  GIN = 'Gin',
  VODKA = 'Rectified Spirits',
  LIQUEURS_BITTERS = 'Liqueurs and Bitters',
  MEDICATED_WINES = 'Medicated Wines',
  MISCELLANEOUS = 'Miscellaneous',
  RECTIFIED_SPRITS = 'Rectified Spirits',
}

export const SRILANKAN_PHONE_NUMBER = /^(?:0\d{9}|(?:\+94|94)\d{9}|\d{9})$/;
