import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import BarPurchaseNewEditForm from '../bar-purchase-new-edit-form';

export default function BarPurchaseCreateView() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Create a new purchase order"
        links={[
          {
            name: 'Bar Purchase Orders',
            href: paths.dashboard.bar.purchase.root(),
          },
          { name: 'Create a new purchase order' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <BarPurchaseNewEditForm />
    </Container>
  );
}
