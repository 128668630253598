import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { fAPIDate } from 'src/utils/format-time';
import { TAB_QUERY } from 'src/utils/common-types';
import { hasPermission } from 'src/utils/has-permissions';
import logToucodeEvent, { APP_EVENTS } from 'src/utils/log-google-events';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import {
  deleteBarPurchaseOrder,
  getBarPurchaseOrders,
  removeBarPurchaseOrderItem,
} from 'src/redux/slices/bar-purchase-order';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TableErrorData from 'src/components/table/table-error-data';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { KitchenPurchaseOrdersStatus } from 'src/types/kitchen-purchase';
import {
  BAR_PURCHASE_ORDERS_STATUS_OPTIONS,
  BarPurchaseOrdersStatus,
  IBarPurchaseOrderListItem,
  IBarPurchaseOrdersTableFilters,
  IBarPurchaseOrdersTableFilterValue,
  IBarPurchaseOrdersTableSort,
} from 'src/types/bar-purchase';

import BarPurchaseTableRow from '../bar-purchase-table-row';
import BarPurchaseTableToolbar from '../bar-purchase-table-toolbar';

const STATUS_OPTIONS = BAR_PURCHASE_ORDERS_STATUS_OPTIONS;

const TABLE_HEAD = [
  { id: 'transactionCode', label: 'PO #', align: 'left', primary: true },
  { id: 'purchaseOrDispatchDate', label: 'Purchase Date', align: 'left', primary: true },
  { id: 'supplier', label: 'Supplier', align: 'left', primary: true },
  { id: 'totalAfterDiscount', label: 'Total', align: 'right', primary: true },
  { id: 'paymentStatus', label: 'Payment Status', align: 'left', primary: true },
  { id: 'updatedAt', label: 'Updated At', align: 'left', primary: true },
  { id: '', primary: true },
];

const defaultFilters: IBarPurchaseOrdersTableFilters = {
  generalSearch: '',
  paymentStatus: null,
  purchaseDate: null,
};

const defaultSortBy: IBarPurchaseOrdersTableSort = {
  sortBy: 'updatedAt',
  order: 'desc',
};

export default function BarPurchaseOrdersListView() {
  const { status, transactions, error } = useAppSelector((state) => state.barPurchaseOrders);

  const [searchParams, setSearchParams] = useSearchParams();

  const { user: authUser } = useAuthContext();

  const currentTab = searchParams.get(TAB_QUERY);

  const table = useTable({
    defaultRowsPerPage: 25,
  });

  const dispatch = useAppDispatcher();

  const { page, rowsPerPage } = table;

  const settings = useSettingsContext();

  const denseHeight = table.dense ? 60 : 80;

  const router = useRouter();

  const [filters, setFilters] = useState(defaultFilters);

  const [sort, setSort] = useState(defaultSortBy);

  const canReset = !isEqual(defaultFilters, filters);

  const [statusTab, setStatusTab] = useState<string>(
    currentTab || BarPurchaseOrdersStatus.CONFIRMED
  );

  const handleFilters = useCallback(
    (name: string, value: IBarPurchaseOrdersTableFilterValue) => {
      table.onResetPage();

      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.bar.purchase.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setStatusTab(newValue);

      setSearchParams({ [TAB_QUERY]: newValue });
    },
    [setSearchParams]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
    setSearchParams({ [TAB_QUERY]: BarPurchaseOrdersStatus.CONFIRMED });
  }, [setSearchParams]);

  const fetchPaginatedBarPurchaseOrders = useCallback(() => {
    dispatch(
      getBarPurchaseOrders({
        page,
        limit: rowsPerPage,
        sortBy: sort.sortBy,
        sort: sort.order,
        generalSearch: filters.generalSearch,
        status: statusTab === BarPurchaseOrdersStatus.ALL ? null : statusTab,
        paymentStatus: filters.paymentStatus ?? null,
        purchaseDate: fAPIDate(filters.purchaseDate) ?? null,
      })
    );

    if (page > 1) {
      logToucodeEvent(APP_EVENTS.TABLE_PAGINATION_MORE_THAN_TWO, {
        page,
        rowsPerPage,
      });
    }
  }, [dispatch, page, rowsPerPage, sort, filters, statusTab]);

  const handleDeleteRow = useCallback(
    async (item: IBarPurchaseOrderListItem, index: number) => {
      dispatch(removeBarPurchaseOrderItem({ itemId: item._id }));

      try {
        await dispatch(deleteBarPurchaseOrder({ item, index })).unwrap();

        enqueueSnackbar('Bar purchase order deleted');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchPaginatedBarPurchaseOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPaginatedBarPurchaseOrders]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Bar Purchase Orders"
        links={[]}
        action={
          hasPermission(
            authUser,
            'any',
            AuthPermissions.CREATE_BAR_INVENTORY_TRANSACTION,
            AuthPermissions.ADMIN_PERMISSION,
            AuthPermissions.SUPER_ADMIN_PERMISSION
          ) && (
            <Button
              component={RouterLink}
              href={paths.dashboard.bar.purchase.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Purchase Order
            </Button>
          )
        }
        sx={{
          mb: { xs: 3 },
        }}
      />

      <Card>
        <Tabs
          value={statusTab}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
          ))}
        </Tabs>

        <BarPurchaseTableToolbar
          filters={filters}
          onFilters={handleFilters}
          handleResetFilters={handleResetFilters}
          canReset={canReset}
        />

        <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '450px' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={sort.order}
                orderBy={sort.sortBy}
                headLabel={TABLE_HEAD.filter((headerItem) => {
                  if (statusTab !== KitchenPurchaseOrdersStatus.DRAFT) return true;
                  return headerItem.id !== 'transactionCode';
                }).filter(({ primary }) => {
                  if (settings.themeLayout === 'vertical') {
                    return primary;
                  }
                  return true;
                })}
                rowCount={transactions.transactions.length}
                numSelected={table.selected.length}
                onSort={(id) => {
                  if (id === sort.sortBy) {
                    setSort((prev) => ({
                      ...prev,
                      order: prev.order === 'asc' ? 'desc' : 'asc',
                    }));
                  } else {
                    setSort({
                      sortBy: id,
                      order: 'asc',
                    });
                  }
                }}
              />

              <TableBody>
                {status === 'loading' ? (
                  [...Array(table.rowsPerPage)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: denseHeight }} />
                  ))
                ) : (
                  <>
                    {transactions?.transactions.map(
                      (row: IBarPurchaseOrderListItem, index: number) => (
                        <BarPurchaseTableRow
                          isDraft={statusTab === KitchenPurchaseOrdersStatus.DRAFT}
                          key={row._id}
                          row={row}
                          selected={table.selected.includes(row._id)}
                          onDeleteRow={() => handleDeleteRow(row, index)}
                          onEditRow={() => handleEditRow(row._id)}
                        />
                      )
                    )}
                  </>
                )}

                {status !== 'failure' && transactions.count === 0 && (
                  <TableNoData
                    message="No purchase orders found"
                    notFound={transactions.count === 0}
                  />
                )}

                {status === 'failure' && <TableErrorData notFound error={error} />}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={transactions.count}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </Container>
  );
}
