import {
  TRANSACTION_DISPATCH_TYPES,
  TRANSACTION_PAYMENT_STATUS,
  TRANSACTION_STATUS,
} from 'src/utils/common-types';

import { IListUser } from 'src/redux/slices/users';

import { IBarListItem } from './bar';
import { IUnitListItem } from './unit';

export type IBarDispatchOrdersTableFilterValue = string | string[] | boolean;

export type IBarDispatchOrdersTableFilters = {
  generalSearch: string;
  type: TRANSACTION_DISPATCH_TYPES | null;
  paymentStatus: TRANSACTION_PAYMENT_STATUS | null;
  dispatchDate: Date | null;
};

export type IBarDispatchOrdersTableSort = {
  sortBy: string;
  order: 'asc' | 'desc';
};

export const BAR_DISPATCH_ORDERS_STATUS_OPTIONS = [
  { value: 'completed', label: 'Confirmed' },
  { value: 'draft', label: 'Draft' },
];

export const BAR_DISPATCH_ORDERS_DISPATCH_TYPE_OPTIONS = [
  { value: TRANSACTION_DISPATCH_TYPES.DISPATCH, label: 'Dispatch' },
  { value: TRANSACTION_DISPATCH_TYPES.DISPOSAL, label: 'Disposal' },
  { value: TRANSACTION_DISPATCH_TYPES.RETURN, label: 'Return' },
];

export const BAR_DISPATCH_ORDERS_PAYMENT_STATUS_OPTIONS = [
  { value: TRANSACTION_PAYMENT_STATUS.PAID, label: 'Paid' },
  { value: TRANSACTION_PAYMENT_STATUS.PARTIALLY_PAID, label: 'Partially Paid' },
  { value: TRANSACTION_PAYMENT_STATUS.NOT_PAID, label: 'Not Paid' },
];

export enum BarDispatchOrdersStatus {
  ALL = 'all',
  CONFIRMED = 'completed',
  DRAFT = 'draft',
}

export interface IBarDispatchOrderListItem {
  _id: string;
  transactionCode: string;
  reason: string;
  description: string | null;
  status: TRANSACTION_STATUS;
  updatedAt: string;
  purchaseOrDispatchDate: string;
  transactionType: TRANSACTION_DISPATCH_TYPES;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  updatedBy: IListUser;
}

export interface IBarDispatchOrderChildItem {
  _id: string;
  inventoryItem: Pick<IBarListItem, '_id' | 'itemName' | 'itemCode'>;
  unit: IUnitListItem;
  quantity: number;
  unitPrice: number;
  discount: number;
  totalAfterDiscount: number;
}

export interface IBarDispatchOrderDetailedItem {
  _id: string;
  transactionCode: string;
  reason: string;
  description: string | null;
  status: TRANSACTION_STATUS;
  updatedAt: string;
  purchaseOrDispatchDate: string;
  discount: number;
  totalAfterDiscount: number;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  items: IBarDispatchOrderChildItem[];
  transactionType: TRANSACTION_DISPATCH_TYPES;
}
