import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// export const tokenExpired = (exp: number) => {
//   // eslint-disable-next-line prefer-const
//   let expiredTimer;

//   const currentTime = Date.now();

//   // Test token expires after 10s
//   // const timeLeft = currentTime + 10000 - currentTime; // ~10s
//   const timeLeft = exp * 1000 - currentTime;

//   clearTimeout(expiredTimer);

//   expiredTimer = setTimeout(() => {
//     alert(`Token expired ${exp} * 1000 = ${exp * 1000} -  ${currentTime} = ${timeLeft}`);

//     localStorage.removeItem('accessToken');

//     window.location.href = paths.auth.jwt.login;
//   }, timeLeft);
// };

let expiredTimer: NodeJS.Timeout | null = null;

export const tokenExpired = (exp: number) => {
  const checkExpiration = () => {
    const currentTime = Date.now();
    const timeLeft = exp * 1000 - currentTime;

    if (timeLeft <= 0) {
      localStorage.removeItem('accessToken');

      window.location.href = paths.auth.jwt.login;
    } else {
      // Check again in one hour or the remaining time if it's less than one hour
      expiredTimer = setTimeout(checkExpiration, Math.min(timeLeft, 3600000));
    }
  };

  // Clear any existing timer before setting a new one
  if (expiredTimer) clearTimeout(expiredTimer);

  // Initial call to check expiration
  checkExpiration();
};

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { exp } = jwtDecode(accessToken);

    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};

export const setNotificationTokenToSession = (notificationToken: string | null) => {
  if (notificationToken) {
    localStorage.setItem('notificationToken', notificationToken);
  } else {
    localStorage.removeItem('notificationToken');
  }
};
