import { useEffect } from 'react';

import { Button } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { getBarDispatchOrderById } from 'src/redux/slices/bar-dispatch-order';

import ErrorContent from 'src/components/error-content';
import { useSettingsContext } from 'src/components/settings';
import { LeftIcon } from 'src/components/carousel/arrow-icons';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import { LoadingScreen } from '../../../components/loading-screen';
import BarDispatchNewEditForm from '../bar-dispatch-new-edit-form';
import { useAppDispatcher, useAppSelector } from '../../../redux/store';

type Props = {
  id: string;
};

export default function BarDispatchOrderEditView({ id }: Props) {
  const { item, status, error } = useAppSelector((state) => state.barDispatchOrders);

  const settings = useSettingsContext();
  const dispatch = useAppDispatcher();

  useEffect(() => {
    dispatch(getBarDispatchOrderById({ itemID: id }));
  }, [dispatch, id]);

  if (status === 'loading' || status === 'idle') {
    return <LoadingScreen />;
  }

  if (status === 'failure') {
    return (
      <ErrorContent
        filled
        title="Failed to load bar dispatch order details"
        description={error}
        action={
          <Button
            component={RouterLink}
            href={paths.dashboard.bar.dispatch.root()}
            variant="contained"
            startIcon={<LeftIcon />}
          >
            Back to Bar Dispatch Orders
          </Button>
        }
        sx={{
          py: 10,
        }}
      />
    );
  }

  if (status === 'success' && item) {
    return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Edit Bar Dispatch Order"
          links={[
            {
              name: 'Bar Dispatch Orders',
              href: paths.dashboard.bar.dispatch.root(),
            },
            { name: 'Edit Bar Dispatch Order' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <BarDispatchNewEditForm currentBarDispatchItem={item} />
      </Container>
    );
  }

  return <span />;
}
