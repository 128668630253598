import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import axiosInstance from 'src/utils/axios';

import { useAppDispatcher } from 'src/redux/store';
import { BAR_CATEGORY_ID } from 'src/config-global';
import {
  TCreateBarDispatchOrder,
  TCreateBarDispatchOrderItem,
} from 'src/redux/slices/bar-dispatch-order';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';

import { IBarListItem } from 'src/types/bar';
import { IBarDispatchOrderDetailedItem } from 'src/types/bar-dispatches';

interface IBarDispatchNewItemRow {
  index: number;
  remove: (index: number) => void;
  isLast: boolean;
  isEdit: boolean;
  isCompleted: boolean;
  dispatchOrder: IBarDispatchOrderDetailedItem | undefined;
}

export default function BarDispatchNewItemRow({
  index,
  remove,
  isLast,
  isEdit,
  isCompleted,
  dispatchOrder,
}: IBarDispatchNewItemRow) {
  const dispatch = useAppDispatcher();

  const [inventorySearchQuery, setInventorySearchQuery] = useState('');

  const [localInventory, setLocalInventory] = useState<IBarListItem[]>([]);

  const [selectedItem, setSelectedItem] = useState<IBarListItem | null>(null);

  const {
    control,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext<TCreateBarDispatchOrder>();

  const defaultHelperText: string = errors.items?.[index] ? ' ' : '';

  const adjustingQuantity = dispatchOrder && isCompleted ? dispatchOrder.items[index].quantity : 0;

  const watchItems = useWatch({
    control,
    name: 'items',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPaginatedKitchenItems = useCallback(
    debounce(async (query) => {
      try {
        const response = await axiosInstance.get(
          `inventory/bar/item?page=&limit=&generalSearch=${query}&itemName=&itemCode=&minQuantity=&maxQuantity=&minPrice=&maxPrice=&suppliers=&categories=${BAR_CATEGORY_ID}&units=&alertEnabled=&alertSent=&availability=&sortBy=itemName&sort=asc&group=&status=${
            isEdit ? '' : 'active'
          }&createdBy=`
        );

        setLocalInventory(response.data.data.items);

        const foundItem = response.data.data.items.find(
          (item: IBarListItem) => item._id === watchItems[index].inventoryItem
        );

        if (foundItem) {
          setSelectedItem(foundItem);
        }
      } catch (err) {
        console.warn(err);
      }
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    fetchPaginatedKitchenItems(inventorySearchQuery);

    return fetchPaginatedKitchenItems.cancel;
  }, [inventorySearchQuery, fetchPaginatedKitchenItems]);

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = Number(event.target.value);

    if (!Number.isInteger(newQuantity) || String(newQuantity).includes('.')) {
      setError(`items.${index}.quantity`, {
        message: `Quantity must be a whole number`,
      });
      setValue(`items.${index}.quantity`, newQuantity);

      return;
    }

    if (selectedItem && newQuantity > selectedItem.quantity + adjustingQuantity) {
      setError(`items.${index}.quantity`, {
        message:
          selectedItem.quantity > 0
            ? `Only ${selectedItem.quantity + adjustingQuantity} left`
            : 'Out of stock',
        type: 'max',
      });
      setValue(`items.${index}.quantity`, newQuantity);
    } else {
      setValue(`items.${index}.quantity`, newQuantity);
      clearErrors(`items.${index}.quantity`);
    }
  };

  return (
    <TableRow>
      <TableCell>
        {index + 1 < 10 ? `0${index + 1}` : index + 1}

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>

      <TableCell
        sx={{
          minWidth: 300,
        }}
      >
        <Controller
          name={`items.${index}.inventoryItem`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              fullWidth
              size="small"
              disabled={isCompleted}
              multiple={false}
              options={localInventory.filter(
                (kitchenItem) =>
                  !watchItems
                    .map((watchItem: TCreateBarDispatchOrderItem) => watchItem.inventoryItem)
                    .includes(kitchenItem._id)
              )}
              isOptionEqualToValue={(option, value) => {
                if (typeof option === 'string') {
                  return option === value;
                }

                if (option && '_id' in option) {
                  return option._id === value;
                }

                return false;
              }}
              getOptionLabel={(option: IBarListItem | string) => {
                if (!option) {
                  return '';
                }

                if (typeof option === 'string') {
                  if (option.length === 0) {
                    return option;
                  }

                  const value = localInventory.find(
                    (kitchenItem: IBarListItem) => kitchenItem._id === option
                  );
                  return value ? value.itemName : '';
                }

                if (option && 'itemName' in option) {
                  return option.itemName;
                }

                return '';
              }}
              renderOption={(props, option) => {
                if (typeof option === 'string') {
                  return (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  );
                }

                return (
                  <li {...props} key={option._id}>
                    {option.itemName}
                  </li>
                );
              }}
              onInputChange={(_, value) => {
                setInventorySearchQuery(value);
              }}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  required
                  helperText={error ? error.message : defaultHelperText}
                  {...params}
                />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  field.onChange(newValue);
                  setSelectedItem(null);
                } else {
                  field.onChange(newValue?._id);

                  setSelectedItem(newValue);
                  setValue(`items.${index}.quantity`, 0);

                  clearErrors(`items.${index}.quantity`);

                  setValue(`items.${index}.unitPrice`, newValue?.price ?? 0);
                }
              }}
            />
          )}
        />
      </TableCell>

      <Tooltip title={!watchItems.at(index)?.inventoryItem ? 'Please select a bar item' : ''}>
        <TableCell>
          <RHFTextField
            fullWidth
            required
            type="number"
            size="small"
            disabled={!watchItems.at(index)?.inventoryItem}
            name={`items.${index}.quantity`}
            onChange={handleQuantityChange}
            inputProps={{
              min: 0,
              max: selectedItem?.quantity || 0,
            }}
            InputProps={{
              endAdornment: errors?.items?.[index]?.quantity ? (
                <InputAdornment position="end">
                  <Tooltip title={errors?.items?.[index]?.quantity?.message || 'Invalid quantity'}>
                    <Iconify icon="eva:info-outline" color="error.main" />
                  </Tooltip>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  {selectedItem && (
                    <Box
                      sx={{
                        typography: 'subtitle2',
                        color: 'text.disabled',
                      }}
                    >
                      {`/ ${selectedItem.quantity + adjustingQuantity}`}
                    </Box>
                  )}
                </InputAdornment>
              ),
            }}
            helperText={defaultHelperText}
            showError={false}
          />
        </TableCell>
      </Tooltip>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          required
          type="number"
          name={`items.${index}.unitPrice`}
          placeholder="0.00"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.unitPrice ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.unitPrice?.message || 'Invalid Unit Price'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          type="number"
          name={`items.${index}.discount`}
          placeholder="0.00"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.discount ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.discount?.message || 'Invalid Discount'}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <RHFTextField
          fullWidth
          size="small"
          type="number"
          name={`items.${index}.totalAfterDiscount`}
          placeholder="0.00"
          onChange={(event) => {
            setValue(
              `items.${index}.unitPrice`,
              Number(event.target.value) / watchItems[index].quantity
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    typography: 'subtitle2',
                    color: 'text.disabled',
                  }}
                >
                  Rs.
                </Box>
              </InputAdornment>
            ),
            endAdornment: errors?.items?.[index]?.totalAfterDiscount ? (
              <InputAdornment position="end">
                <Tooltip title={errors?.items?.[index]?.totalAfterDiscount?.message || ''}>
                  <Iconify icon="eva:info-outline" color="error.main" />
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          helperText={defaultHelperText}
          showError={false}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip title={isLast ? 'Last item cannot be deleted' : 'Delete'}>
          <span>
            <IconButton disabled={isLast} color="error" onClick={() => remove(index)}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </span>
        </Tooltip>

        <FormHelperText>{defaultHelperText}</FormHelperText>
      </TableCell>
    </TableRow>
  );
}
