import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICommonInventoryItem } from 'src/types/item';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  items: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    items: ICommonInventoryItem[];
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  items: {
    count: 0,
    page: 1,
    pageSize: 10,
    pageCount: 0,
    items: [],
  },
};

const itemSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaginatedItems.fulfilled, (state, action) => {
        state.status = 'success';
        state.items = action.payload;
      })
      .addCase(getPaginatedItems.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default itemSlice.reducer;

export const getPaginatedItems = createAsyncThunk(
  'item/getAll',
  async (
    params: {
      page: number | null;
      limit: number | null;
      generalSearch?: string | null;
      itemName?: string | null;
      itemCode?: string | null;
      minQuantity?: number | null;
      maxQuantity?: number | null;
      minPrice?: number | null;
      maxPrice?: number | null;
      suppliers?: string[];
      supplierSeparator?: ',' | '|';
      units?: string[];
      unitsSeparator?: ',' | '|';
      alertSent?: string | null;
      availability?: string | null;
      alertEnabled?: boolean | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: null,
      limit: null,
      suppliers: [],
      supplierSeparator: ',',
      units: [],
      unitsSeparator: ',',
      generalSearch: null,
      alertEnabled: null,
      itemName: null,
      itemCode: null,
      maxPrice: null,
      maxQuantity: null,
      minPrice: null,
      minQuantity: null,
      alertSent: null,
      availability: null,
      sort: 'desc',
      sortBy: 'updatedAt',
    }
  ) => {
    const {
      page,
      limit,
      generalSearch,
      itemName,
      itemCode,
      minQuantity,
      maxQuantity,
      minPrice,
      maxPrice,
      suppliers,
      supplierSeparator,
      units,
      unitsSeparator,
      alertEnabled,
      alertSent,
      availability,
      sortBy,
      sort,
    } = params;

    try {
      const response = await axios.get(
        `inventory/kitchen/item?page=${page ? page + 1 : ''}&limit=${limit ?? ''}&generalSearch=${
          generalSearch ?? ''
        }&itemName=${itemName ?? ''}&itemCode=${itemCode ?? ''}&minQuantity=${
          minQuantity ?? ''
        }&maxQuantity=${maxQuantity ?? ''}&minPrice=${minPrice ?? ''}&maxPrice=${
          maxPrice ?? ''
        }&suppliers=${suppliers ? suppliers.join(supplierSeparator) : ''}&categories=&units=${
          units ? units.join(unitsSeparator) : ''
        }&alertEnabled=${alertEnabled ?? ''}&alertSent=${alertSent ?? ''}&availability=${
          availability ?? ''
        }&sortBy=${sortBy}&sort=${sort ?? ''}&group=&createdBy=&status=active`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const updateCommonInventoryItem = createAsyncThunk(
  'inventory/updateInventory',
  async (params: {
    inventoryId: string;
    updatingInventory: ICommonInventoryItem;
    suppliers: string[];
  }) => {
    const { updatingInventory, inventoryId, suppliers } = params;
    const response = await axios.put(`inventory/kitchen/item/${inventoryId}`, {
      ...updatingInventory,
      category: updatingInventory.category.map((category) => category._id),
      unit: updatingInventory.unit._id,
      suppliers,
    });
    return response.data.data;
  }
);
