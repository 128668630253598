import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from 'src/components/iconify';

import { ISupplierTableFilters, ISupplierTableFilterValue } from 'src/types/supplier';

type Props = {
  filters: ISupplierTableFilters;
  onFilters: (name: string, value: ISupplierTableFilterValue) => void;
  handleResetFilters: VoidFunction;
  canReset: boolean;
};

export default function SupplierTableToolbar({
  filters,
  onFilters,
  handleResetFilters,
  canReset,
}: Props) {
  const [input, setInput] = useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      onFilters('generalSearch', value);
    }, 500),
    []
  );

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInput(value);

    debouncedSearch(value);
  };

  const clearSearchText = () => {
    setInput('');

    debouncedSearch('');
  };

  // const clearAll = useCallback(() => {
  //   setInput('');
  //   handleResetFilters();
  // }, [handleResetFilters]);

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={input}
          onChange={handleSearchChange}
          placeholder="Search for name, contact person, phone number, email address..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
            endAdornment:
              input.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearchText}>
                    <Iconify icon="eva:close-fill" color="text.disabled" />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          inputProps={{
            autoComplete: 'off',
          }}
        />
      </Stack>

      {/* {canReset && (
        <Button
          color="error"
          onClick={clearAll}
          variant="text"
          sx={{ textDecoration: 'underline' }}
        >
          Clear
        </Button>
      )} */}
    </Stack>
  );
}
