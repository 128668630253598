import { TRANSACTION_DISPATCH_TYPES, TRANSACTION_STATUS } from 'src/utils/common-types';

import { IUnitListItem } from './unit';
import { IKitchenListItem } from './kitchen';

export type IKitchenDispatchOrdersTableFilterValue =
  | string
  | string[]
  | { min: number; max: number }
  | boolean;

export type IKitchenDispatchOrdersTableFilters = {
  generalSearch: string;
  type: TRANSACTION_DISPATCH_TYPES | null;
  dispatchDate: Date | null;
};

export type IKitchenDispatchOrdersTableSort = {
  sortBy: string;
  order: 'asc' | 'desc';
};

export const KITCHEN_DISPATCH_ORDERS_STATUS_OPTIONS = [
  { value: 'completed', label: 'Confirmed' },
  { value: 'draft', label: 'Draft' },
];

export const KITCHEN_DISPATCH_ORDERS_TYPE_OPTIONS = [
  { value: TRANSACTION_DISPATCH_TYPES.DISPATCH, label: 'Dispatch' },
  { value: TRANSACTION_DISPATCH_TYPES.DISPOSAL, label: 'Disposal' },
  { value: TRANSACTION_DISPATCH_TYPES.RETURN, label: 'Return' },
];

export enum KitchenDispatchOrdersStatus {
  ALL = 'all',
  CONFIRMED = 'completed',
  DRAFT = 'draft',
}

export interface IKitchenDispatchOrderListItem {
  _id: string;
  transactionCode: string;
  reason: string;
  description: string | null;
  status: TRANSACTION_STATUS;
  updatedAt: string;
  purchaseOrDispatchDate: string;
  transactionType: TRANSACTION_DISPATCH_TYPES;
}

export interface IKitchenDispatchOrderChildItem {
  _id: string;
  inventoryItem: Pick<IKitchenListItem, '_id' | 'itemName' | 'itemCode'>;
  unit: IUnitListItem;
  quantity: number;
  unitPrice?: number;
  discount?: number;
  totalAfterDiscount?: number;
}

export interface IKitchenDispatchOrderDetailedItem {
  _id: string;
  transactionCode: string;
  reason: string;
  description: string | null;
  status: TRANSACTION_STATUS;
  updatedAt: string;
  purchaseOrDispatchDate: string;
  items: IKitchenDispatchOrderChildItem[];
  transactionType: TRANSACTION_DISPATCH_TYPES;
  discount?: number;
  totalAfterDiscount?: number;
  isPercentageDiscount?: boolean;
}
