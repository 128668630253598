import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

interface IInitialState {
  summary: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    data: {
      KITCHEN: {
        'in-stock': number;
        'low-stock': number;
        'out-of-stock': number;
      };
      BAR: {
        'in-stock': number;
        'low-stock': number;
        'out-of-stock': number;
      };
    };
  };
}

const initialState: IInitialState = {
  summary: {
    status: 'idle',
    error: undefined,
    data: {
      KITCHEN: {
        'in-stock': 0,
        'low-stock': 0,
        'out-of-stock': 0,
      },
      BAR: {
        'in-stock': 0,
        'low-stock': 0,
        'out-of-stock': 0,
      },
    },
  },
};

const inventoryAnalysisSlice = createSlice({
  name: 'inventoryAnalysis',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryAnalysis.pending, (state) => {
        state.summary.status = 'loading';
      })
      .addCase(getInventoryAnalysis.fulfilled, (state, action) => {
        state.summary.status = 'success';
        state.summary.data = action.payload;
      })
      .addCase(getInventoryAnalysis.rejected, (state, action) => {
        state.summary.status = 'failure';
        state.summary.error = action.error.message;
      });
  },
});

export default inventoryAnalysisSlice.reducer;

export const getInventoryAnalysis = createAsyncThunk('getInventoryAnalysis', async () => {
  try {
    const response = await axios.get(`inventory/item/availability`);

    return response.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
});
