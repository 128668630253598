import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Link, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDateTime } from 'src/utils/format-time';
import { hasPermission } from 'src/utils/has-permissions';
import logToucodeEvent, { APP_EVENTS } from 'src/utils/log-google-events';

import { useAuthContext } from 'src/auth/hooks';
import { AccountStatus } from 'src/config-global';
import { IListUser } from 'src/redux/slices/users';
import { AuthPermissions } from 'src/auth/permissions';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import UserResetPassword from './user-reset-password';

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IListUser;
  onDeleteRow: VoidFunction;
};

export default function UserTableRow({ row, selected, onEditRow, onDeleteRow }: Props) {
  const { _id, firstName, lastName, email, jobRole, status, updatedAt } = row;

  const { user: authUser } = useAuthContext();

  const confirm = useBoolean();
  const resetPassword = useBoolean();

  const popover = usePopover();

  return (
    <>
      <Link
        component={RouterLink}
        href={paths.dashboard.user.view(_id)}
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'contents',
        }}
      >
        <TableRow hover selected={selected}>
          <TableCell sx={{ display: 'flex', alignItems: 'left' }}>
            <Avatar alt={firstName} src={firstName} sx={{ mr: 2 }} />

            <ListItemText
              primary={`${firstName} ${lastName}`}
              secondary={email}
              primaryTypographyProps={{ typography: 'body2' }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
              }}
            />
          </TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap' }}>{jobRole}</TableCell>

          <TableCell>
            <Label
              variant="soft"
              color={
                (status === AccountStatus.ACTIVE && 'success') ||
                (status === AccountStatus.BLOCKED && 'error') ||
                'default'
              }
            >
              {status === AccountStatus.ACTIVE ? 'Active' : 'Blocked'}
            </Label>
          </TableCell>

          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
            {fDateTime(updatedAt)}
          </TableCell>

          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            <Link component={RouterLink} href={paths.dashboard.user.view(_id)} mr={2}>
              <Button variant="soft" color="primary">
                View More
              </Button>
            </Link>

            {hasPermission(
              authUser,
              'any',
              AuthPermissions.UPDATE_USER,
              AuthPermissions.CHANGE_PASSWORD,
              AuthPermissions.DELETE_USER,
              AuthPermissions.ADMIN_PERMISSION,
              AuthPermissions.SUPER_ADMIN_PERMISSION
            ) && (
              <Tooltip title="More options">
                <IconButton
                  color={popover.open ? 'inherit' : 'default'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    popover.onOpen(e);
                  }}
                >
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      </Link>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 180 }}
      >
        {hasPermission(
          authUser,
          'any',
          AuthPermissions.UPDATE_USER,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        ) && (
          <MenuItem
            onClick={() => {
              onEditRow();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}

        {hasPermission(
          authUser,
          'any',
          AuthPermissions.CHANGE_PASSWORD,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        ) && (
          <MenuItem
            onClick={() => {
              resetPassword.onTrue();
              popover.onClose();

              logToucodeEvent(APP_EVENTS.RESET_PASSWORD);
            }}
            sx={{ color: 'error.dark' }}
          >
            <Iconify icon="solar:lock-password-bold" />
            Reset Password
          </MenuItem>
        )}

        {hasPermission(
          authUser,
          'any',
          AuthPermissions.DELETE_USER,
          AuthPermissions.ADMIN_PERMISSION,
          AuthPermissions.SUPER_ADMIN_PERMISSION
        ) &&
          authUser?._id !== _id && (
            <MenuItem
              onClick={() => {
                confirm.onTrue();
                popover.onClose();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="solar:trash-bin-trash-bold" />
              Delete
            </MenuItem>
          )}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete User"
        content={`Are you sure want to delete ${firstName}? This action will permenantly delete ${firstName}'s details from the system`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
              onDeleteRow();
            }}
          >
            Delete
          </Button>
        }
      />

      <UserResetPassword user={row} open={resetPassword.value} onClose={resetPassword.onFalse} />
    </>
  );
}
