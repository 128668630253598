import { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { Button, IconButton } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { IPermission } from 'src/redux/slices/permissions';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import { IUserTableFilters, IUserTableFilterValue } from 'src/types/user';

type Props = {
  filters: IUserTableFilters;
  onFilters: (name: string, value: IUserTableFilterValue) => void;
  permissions: IPermission[];
  query: string;
  onHandleSearch: (value: string) => void;
  handleResetFilters: VoidFunction;
  canReset: boolean;
};

export default function UserTableToolbar({
  filters,
  onFilters,
  permissions,
  onHandleSearch,
  query,
  handleResetFilters,
  canReset,
}: Props) {
  const handleFilterPermissions = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'permissions',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const clearSearchText = () => {
    onHandleSearch('');
  };

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={query}
          onChange={(event) => onHandleSearch(event.target.value)}
          placeholder="Search for first name, last name, email address..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
            endAdornment:
              query.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearchText}>
                    <Iconify icon="eva:close-fill" color="text.disabled" />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          inputProps={{
            autoComplete: 'off',
          }}
        />
      </Stack>

      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}
      >
        <InputLabel>Permissions</InputLabel>

        <Select
          multiple
          value={filters.permissions}
          onChange={handleFilterPermissions}
          input={<OutlinedInput label="Permissions" />}
          renderValue={(selected) => (
            <Label variant="soft" color="primary">
              Permissions {selected.length < 10 ? `0${selected.length}` : selected.length}
            </Label>
          )}
          MenuProps={{
            PaperProps: {
              sx: { maxHeight: 240 },
            },
          }}
        >
          {permissions.map((option: IPermission) => (
            <MenuItem key={option._id} value={option.description}>
              <Checkbox
                disableRipple
                size="small"
                checked={filters.permissions.includes(option.description)}
              />
              {option.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {canReset && (
        <Button
          color="error"
          onClick={handleResetFilters}
          variant="text"
          sx={{ textDecoration: 'underline' }}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}
