import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { InventoryItemGroup } from 'src/utils/common-types';

import { IBarSummarySnapshotStats, IBarSummaryStats } from 'src/types/bar-summary';

import axios from '../../utils/axios';

export interface IContribution {
  _id: string;
  itemName: string;
  quantity: number;
  volume: number;
  totalVolume: number;
}

interface IInitialState {
  summary: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    data: IBarSummaryStats | null;
  };
  snapshot: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    data: IBarSummarySnapshotStats | null;
  };
  contribution: {
    status: 'idle' | 'loading' | 'success' | 'failure';
    error: string | undefined;
    data: IContribution[];
  };
}

const initialState: IInitialState = {
  summary: {
    status: 'idle',
    error: undefined,
    data: null,
  },
  snapshot: {
    status: 'idle',
    error: undefined,
    data: null,
  },
  contribution: {
    status: 'idle',
    error: undefined,
    data: [],
  },
};

const barSummarySlice = createSlice({
  name: 'barSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBarSummary.pending, (state) => {
        state.summary.status = 'loading';
      })
      .addCase(getBarSummary.fulfilled, (state, action) => {
        state.summary.status = 'success';
        state.summary.data = action.payload;
      })
      .addCase(getBarSummary.rejected, (state, action) => {
        state.summary.status = 'failure';
        state.summary.error = action.error.message;
      })
      .addCase(getBarSummarySnapshot.pending, (state) => {
        state.snapshot.status = 'loading';
      })
      .addCase(getBarSummarySnapshot.fulfilled, (state, action) => {
        state.snapshot.status = 'success';
        state.snapshot.data = action.payload;
      })
      .addCase(getBarSummarySnapshot.rejected, (state, action) => {
        state.snapshot.status = 'failure';
        state.snapshot.error = action.error.message;
      })

      .addCase(getBarContribution.pending, (state) => {
        state.contribution.status = 'loading';
        state.contribution.data = [];
      })
      .addCase(getBarContribution.fulfilled, (state, action) => {
        state.contribution.status = 'success';
        state.contribution.data = action.payload;
      })
      .addCase(getBarContribution.rejected, (state, action) => {
        state.contribution.status = 'failure';
        state.contribution.error = action.error.message;
      });
  },
});

export default barSummarySlice.reducer;

export const getBarSummary = createAsyncThunk(
  'barSummary/getBarSummary',
  async (params: { startDate: string | null; endDate: string | null }) => {
    try {
      const { startDate, endDate } = params;
      const response = await axios.get(
        `inventory/item/group/count/history?startDate=${startDate ?? ''}&endDate=${endDate ?? ''}`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getBarSummarySnapshot = createAsyncThunk(
  'barSummary/getBarSummarySnapshot',
  async () => {
    try {
      const response = await axios.get(`inventory/item/group/count`);

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getBarContribution = createAsyncThunk(
  'barSummary/getBarContribution',
  async (params: { group: InventoryItemGroup; total: number }) => {
    try {
      if (params.total > 0) {
        const response = await axios.get(`inventory/item/group/details?group=${params.group}`);

        return response.data.data.items;
      }
      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
