import { TRANSACTION_PAYMENT_STATUS, TRANSACTION_TYPE } from 'src/utils/common-types';

import { IListUser } from 'src/redux/slices/users';

import { IUnitListItem } from './unit';
import { ICategoryListItem } from './category';
import { ISupplierListItem } from './supplier';

export function isMinMaxRange(
  value: IKitchenTableFilterValue
): value is { min: number; max: number } {
  return typeof value === 'object' && value !== null && 'min' in value && 'max' in value;
}

export type IKitchenTableFilterValue = string | string[] | { min: number; max: number } | boolean;

export type IKitchenTableFilters = {
  generalSearch: string;
  minQuantity: number | null;
  maxQuantity: number | null;
  alertsEnabled: 'all' | 'enabled' | 'disabled';
  units: string[];
};

export type IKitchenTableSort = {
  sortBy: string;
  order: 'asc' | 'desc';
};

export const KITCHEN_STATUS_OPTIONS = [
  { value: 'in-stock', label: 'In Stock' },
  { value: 'low-stock', label: 'Low Stock' },
  { value: 'out-of-stock', label: 'Out of Stock' },
];

export enum KitchenStatus {
  ALL = 'all',
  IN_STOCK = 'in-stock',
  OUT_OF_STOCK = 'out-of-stock',
  RUNNING_LOW = 'low-stock',
}

export enum KitchenAlertStatus {
  ALL = 'all',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export const KITCHEN_ALERT_OPTIONS = [
  { value: KitchenAlertStatus.ENABLED, label: 'Enabled' },
  { value: KitchenAlertStatus.DISABLED, label: 'Disabled' },
];

export interface IKitchenListItem {
  _id: string;
  itemName: string;
  itemCode: string;
  quantity: number;
  unit: IUnitListItem;
  reorderThreshold: number;
  alertEnabled: boolean;
  updatedAt: string;
  availability: KitchenStatus;
}

export interface IKitchenItem {
  _id: string;
  itemName: string;
  itemCode: string;
  quantity: number;
  unit: IUnitListItem;
  category: ICategoryListItem[];
  suppliers: ISupplierListItem[];
  reorderThreshold: number;
  alertEnabled: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: IListUser;
  availability: KitchenStatus;
}

export interface IKitchenHistory {
  quantity: number;
  transactionType: TRANSACTION_TYPE;
  updatedAt: string;
  transactionDetails: IKitchenHistoryDetails;
}

export interface IKitchenHistoryDetails {
  _id: string;
  transactionCode: string;
  paymentStatus: TRANSACTION_PAYMENT_STATUS;
  purchaseOrDispatchDate: string;
}
