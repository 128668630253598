import React, { useCallback, useEffect, useState } from 'react';

import {
  Card,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { fAPIDate } from 'src/utils/format-time';
import { InventoryItemGroup } from 'src/utils/common-types';

import { getBarSummary } from 'src/redux/slices/bar-summary';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';

import Scrollbar from 'src/components/scrollbar';
import CustomDatePicker from 'src/components/custom-datepicker/custom-datepicker';

import { IBarSummaryStats } from 'src/types/bar-summary';

import BarSummaryColumnChart from '../analytics-website-visits';

const LiquorTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  borderColor: theme.palette.divider,
  borderWidth: '1px',
}));

function BarSummaryRange() {
  const dispatch = useAppDispatcher();
  const {
    summary: { data },
  } = useAppSelector((state) => state.barSummary);

  const summaryData: IBarSummaryStats | null = data;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const validateDates = useCallback(() => {
    const isValid = startDate <= endDate;
    setStartDateError(!isValid);
    setEndDateError(!isValid);
    return isValid;
  }, [startDate, endDate]);

  const fetchSummary = useCallback(() => {
    if (validateDates()) {
      dispatch(
        getBarSummary({
          startDate: fAPIDate(startDate),
          endDate: fAPIDate(endDate),
        })
      );
    }
  }, [dispatch, startDate, endDate, validateDates]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handleStartDate = useCallback(
    (date: Date | null) => {
      if (date) {
        setStartDate(date);
        setStartDateError(false);
        if (date > endDate) setEndDateError(true);
      }
    },
    [endDate]
  );

  const handleEndDate = useCallback(
    (date: Date | null) => {
      if (date) {
        setEndDate(date);
        setEndDateError(false);
        if (date < startDate) setStartDateError(true);
      }
    },
    [startDate]
  );

  return (
    <Stack>
      <Typography variant="h5">Bar Item Usage</Typography>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="start"
        gap={{
          xs: 2,
        }}
        mb={4}
        mt={4}
      >
        <CustomDatePicker
          label="Start Date"
          value={startDate}
          disableFuture
          onChange={handleStartDate}
          slotProps={{
            textField: {
              sx: {
                minWidth: 200,
              },
              error: startDateError,
              helperText: startDateError ? 'Start date should be on or before end date' : '',
            },
          }}
        />

        <CustomDatePicker
          label="End Date"
          value={endDate}
          disableFuture
          onChange={handleEndDate}
          slotProps={{
            textField: {
              sx: {
                minWidth: 200,
              },
              error: endDateError,
              helperText: endDateError ? 'End date should be on or after start date' : '',
            },
          }}
        />
      </Stack>

      <Card sx={{ mb: 5 }}>
        <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '200px' }}>
          <Scrollbar>
            <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
              <TableHead>
                <TableRow>
                  <LiquorTableCell rowSpan={3} sx={{ minWidth: '200px' }}>
                    Particulars
                  </LiquorTableCell>
                  <LiquorTableCell rowSpan={2} sx={{ whiteSpace: 'nowrap' }}>
                    Malt Liquor (Beer Stout, Porter)
                  </LiquorTableCell>
                  <LiquorTableCell colSpan={2} align="center">
                    Wines
                  </LiquorTableCell>
                  <LiquorTableCell colSpan={5} align="center">
                    Spirits
                  </LiquorTableCell>
                  <LiquorTableCell rowSpan={2} align="center" sx={{ whiteSpace: 'nowrap' }}>
                    Liqueurs and Bitters
                  </LiquorTableCell>
                  <LiquorTableCell rowSpan={2} align="center" sx={{ whiteSpace: 'nowrap' }}>
                    Medicated Wines
                  </LiquorTableCell>
                  <LiquorTableCell rowSpan={2} align="center" sx={{ whiteSpace: 'nowrap' }}>
                    Miscellaneous Including Cider Milk Punch ang Ginger Wines
                  </LiquorTableCell>
                </TableRow>
                <TableRow>
                  <LiquorTableCell sx={{ whiteSpace: 'nowrap' }}>Sparkling Wine</LiquorTableCell>
                  <LiquorTableCell sx={{ whiteSpace: 'nowrap' }}>Other Wine</LiquorTableCell>
                  <LiquorTableCell>Whisky</LiquorTableCell>
                  <LiquorTableCell>Brandy</LiquorTableCell>
                  <LiquorTableCell>Rum</LiquorTableCell>
                  <LiquorTableCell>Gin</LiquorTableCell>
                  <LiquorTableCell sx={{ whiteSpace: 'nowrap' }}>Rectified Spirits</LiquorTableCell>
                </TableRow>
                <TableRow>
                  <LiquorTableCell>Litres</LiquorTableCell>
                  <LiquorTableCell>Litres</LiquorTableCell>
                  <LiquorTableCell>Litres</LiquorTableCell>

                  <LiquorTableCell>Litres</LiquorTableCell>
                  <LiquorTableCell>Litres</LiquorTableCell>
                  <LiquorTableCell>Litres</LiquorTableCell>
                  <LiquorTableCell>Litres</LiquorTableCell>
                  <LiquorTableCell>Litres</LiquorTableCell>

                  <LiquorTableCell>Litres</LiquorTableCell>
                  <LiquorTableCell>Litres</LiquorTableCell>
                  <LiquorTableCell>Litres</LiquorTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>Received</TableCell>
                  <TableCell>{summaryData?.['Malt Liquor'].purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.['Sparkling Wines'].purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.['Other Wines'].purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.Whisky.purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.Brandy.purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.Rum.purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.Gin.purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.['Rectified Spirits'].purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.['Liqueurs and Bitters'].purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.['Medicated Wines'].purchasedVolume}</TableCell>
                  <TableCell>{summaryData?.Miscellaneous.purchasedVolume}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>Sold to General Public</TableCell>
                  <TableCell>{summaryData?.['Malt Liquor'].dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.['Sparkling Wines'].dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.['Other Wines'].dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.Whisky.dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.Brandy.dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.Rum.dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.Gin.dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.['Rectified Spirits'].dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.['Liqueurs and Bitters'].dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.['Medicated Wines'].dispatchedVolume}</TableCell>
                  <TableCell>{summaryData?.Miscellaneous.dispatchedVolume}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Card>

      <BarSummaryColumnChart
        title=""
        subheader=""
        chart={{
          labels: Object.values(InventoryItemGroup),
          series: [
            {
              name: 'Purchase Volume',
              type: 'column',
              fill: 'solid',
              data: summaryData
                ? Object.values(InventoryItemGroup).map(
                    (group) => summaryData[group].purchasedVolume
                  )
                : [],
            },
            {
              name: 'Dispatch Volume',
              type: 'column',
              fill: 'solid',
              data: summaryData
                ? Object.values(InventoryItemGroup).map(
                    (group) => summaryData[group].dispatchedVolume
                  )
                : [],
            },

            {
              name: 'Returned Volume',
              type: 'column',
              fill: 'solid',
              data: summaryData
                ? Object.values(InventoryItemGroup).map(
                    (group) => summaryData[group].returnedVolume
                  )
                : [],
            },

            {
              name: 'Disposed Volume',
              type: 'column',
              fill: 'solid',
              data: summaryData
                ? Object.values(InventoryItemGroup).map(
                    (group) => summaryData[group].disposedVolume
                  )
                : [],
            },
          ],
          options: {
            xaxis: {
              labels: {
                rotateAlways: true,
              },
            },
          },
        }}
      />
    </Stack>
  );
}

export default BarSummaryRange;
