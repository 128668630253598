import { useLocation } from 'react-router-dom';

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const { pathname } = useLocation();
  try {
    const [, segment] = path.split('/').filter(Boolean);

    const cleanedSegment = segment.split('?')[0];

    return cleanedSegment === pathname.split('/')[2];
  } catch (e) {
    return false;
  }
}
