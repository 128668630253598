import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomDatePicker from 'src/components/custom-datepicker/custom-datepicker';

import { KITCHEN_PURCHASE_ORDERS_PAYMENT_STATUS_OPTIONS } from 'src/types/kitchen-purchase';
import {
  IBarPurchaseOrdersTableFilters,
  IBarPurchaseOrdersTableFilterValue,
} from 'src/types/bar-purchase';

type Props = {
  filters: IBarPurchaseOrdersTableFilters;
  onFilters: (name: string, value: IBarPurchaseOrdersTableFilterValue) => void;
  handleResetFilters: VoidFunction;
  canReset: boolean;
};

export default function BarPurchaseTableToolbar({
  filters,
  onFilters,
  handleResetFilters,
  canReset,
}: Props) {
  const [input, setInput] = useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      onFilters('generalSearch', value);
    }, 500),
    []
  );

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleFilterPaymentStatus = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters('paymentStatus', event.target.value);
    },
    [onFilters]
  );

  const handleFilterPurchaseDate = useCallback(
    (date: any) => {
      onFilters('purchaseDate', date);
    },
    [onFilters]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInput(value);

    debouncedSearch(value);
  };

  const clearSearchText = () => {
    setInput('');

    debouncedSearch('');
  };

  const clearAll = useCallback(() => {
    setInput('');
    handleResetFilters();
  }, [handleResetFilters]);

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={input}
          onChange={handleSearchChange}
          placeholder="Search for purchase order #..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
            endAdornment:
              input.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearchText}>
                    <Iconify icon="eva:close-fill" color="text.disabled" />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          inputProps={{
            autoComplete: 'off',
          }}
        />
      </Stack>

      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 180 },
        }}
      >
        <InputLabel>Payment Status</InputLabel>

        <Select
          multiple={false}
          value={filters.paymentStatus ? [filters.paymentStatus] : []}
          onChange={handleFilterPaymentStatus}
          input={<OutlinedInput label="Payment Status" />}
          renderValue={(selected: any) => (
            <Label variant="soft" color="primary">
              {selected}
            </Label>
          )}
          sx={{ textTransform: 'capitalize' }}
        >
          {KITCHEN_PURCHASE_ORDERS_PAYMENT_STATUS_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomDatePicker
        disableFuture
        label="Purchase Date"
        value={filters.purchaseDate}
        onChange={handleFilterPurchaseDate}
      />

      {canReset && (
        <Button
          color="error"
          onClick={clearAll}
          variant="text"
          sx={{ textDecoration: 'underline' }}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}
