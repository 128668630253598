import { forwardRef } from 'react';

import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import { APP_VERSION_NUMBER } from 'src/config-global';

import Label from '../label';
import { useSettingsContext } from '../settings';

const VendorLogo = forwardRef<HTMLDivElement, BoxProps>(({ sx, ...other }, ref) => {
  const settings = useSettingsContext();

  const logo =
    settings.themeLayout !== 'mini' ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'center',
          ...sx,
        }}
      >
        <Typography variant="caption">Powered by</Typography>
        <a href="https://www.toucode.com" target="_blank" rel="noopener noreferrer">
          <Box
            ref={ref}
            component="div"
            sx={{
              width: 60,
              height: 'auto',
              display: 'inline-flex',
            }}
            {...other}
          >
            <img
              src="/assets/toucode.svg"
              alt="Toucode (PVT) LTD"
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </a>

        <Label variant="soft">
          <Typography variant="caption" sx={{ fontSize: '9px', letterSpacing: '2px' }}>
            {APP_VERSION_NUMBER}
          </Typography>
        </Label>
      </Box>
    ) : (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'center',
          ...sx,
        }}
      >
        <a href="https://www.toucode.com" target="_blank" rel="noopener noreferrer">
          <Box
            ref={ref}
            component="div"
            sx={{
              width: 20,
              height: 'auto',
            }}
            {...other}
          >
            <img
              src="/assets/toucode-compact.svg"
              alt="Toucode (PVT) LTD"
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </a>
      </Box>
    );

  return logo;
});

export default VendorLogo;
