import { IUser } from 'src/redux/slices/users';
import { AuthPermissions } from 'src/auth/permissions';
import { BAR_CATEGORY_ID, KITCHEN_CATEGORY_ID } from 'src/config-global';

export const hasPermission = (
  user: IUser | null,
  permissionsType: 'any' | 'all',
  ...requiredPermissions: string[]
): boolean => {
  if (!user || !user.permissions) return false;

  const userPermissionNames = user.permissions.map((permission) => permission.name);

  if (permissionsType === 'all') {
    return requiredPermissions.every((permission) => userPermissionNames.includes(permission));
  }

  if (permissionsType === 'any') {
    return requiredPermissions.some((permission) => userPermissionNames.includes(permission));
  }

  return false;
};

export const authCategories = (user: IUser | null): string[] => {
  const categories = [];

  if (
    hasPermission(
      user,
      'any',
      AuthPermissions.ADMIN_PERMISSION,
      AuthPermissions.SUPER_ADMIN_PERMISSION
    )
  ) {
    categories.push(KITCHEN_CATEGORY_ID);
    categories.push(BAR_CATEGORY_ID);
    return categories;
  }

  const hasKitchenPermissions = hasPermission(user, 'all', AuthPermissions.READ_KITCHEN_ITEM);

  if (hasKitchenPermissions) {
    categories.push(KITCHEN_CATEGORY_ID);
  }

  const hasBarPermissions = hasPermission(user, 'all', AuthPermissions.READ_BAR_ITEM);

  if (hasBarPermissions) {
    categories.push(BAR_CATEGORY_ID);
  }

  return categories;
};
