import { useCallback, useEffect } from 'react';

import { Box, Grid } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { getInventoryAnalysis } from 'src/redux/slices/inventory-analysis';

import AnalyticsWidgetSummary from '../analytics-widget-summary';

export default function InventoryAnalysis() {
  const dispatch = useAppDispatcher();
  const {
    summary: { data },
  } = useAppSelector((state) => state.inventoryAnalysis);

  const fetchInventoryAnalysis = useCallback(() => {
    dispatch(getInventoryAnalysis());
  }, [dispatch]);

  useEffect(() => {
    fetchInventoryAnalysis();
  }, [fetchInventoryAnalysis]);

  return (
    <Grid
      container
      spacing={{
        xs: 1,
        md: 3,
      }}
      my={5}
      sx={{ width: 1 }}
    >
      <Grid item xs={12} sm={6} md={3}>
        <Box
          component={RouterLink}
          href={paths.dashboard.kitchen.root('low-stock')}
          sx={{ textDecoration: 'none' }}
        >
          <AnalyticsWidgetSummary
            title="Low Stock Items"
            total={data.KITCHEN['low-stock']}
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_bag.png" />}
            subtitle="Kitchen"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Box
          component={RouterLink}
          href={paths.dashboard.bar.root('low-stock')}
          sx={{ textDecoration: 'none' }}
        >
          <AnalyticsWidgetSummary
            title="Low Stock Items"
            total={data.BAR['low-stock']}
            color="info"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_users.png" />}
            subtitle="Bar"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Box
          component={RouterLink}
          href={paths.dashboard.kitchen.root('out-of-stock')}
          sx={{ textDecoration: 'none' }}
        >
          <AnalyticsWidgetSummary
            title="Out of Stock Items"
            total={data.KITCHEN['out-of-stock']}
            color="warning"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_buy.png" />}
            subtitle="Kitchen"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Box
          component={RouterLink}
          href={paths.dashboard.bar.root('out-of-stock')}
          sx={{ textDecoration: 'none' }}
        >
          <AnalyticsWidgetSummary
            title="Out of Stock Items"
            total={data.BAR['out-of-stock']}
            color="error"
            icon={<img alt="icon" src="/assets/icons/glass/ic_glass_message.png" />}
            subtitle="Bar"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
