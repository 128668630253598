import { addMonths, format, startOfYear } from 'date-fns';

export function getMonthsOfCurrentYear(): string[] {
  const months: string[] = [];
  const currentYear = new Date();
  const start = startOfYear(currentYear);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 12; i++) {
    const month = addMonths(start, i);
    months.push(format(month, 'MMMM')); // 'MMMM' gives the full month name (e.g., January)
  }

  return months;
}
