import { Navigate } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import KitchenPurchaseOrdersListView from 'src/sections/kitchen-purchases/view/kitchen-purchase-list-view';

export default function KitchenPurchaseListPage() {
  const { user: authUser } = useAuthContext();

  return (
    <>
      <Helmet>
        <title> Kitchen: Purchase Order</title>
      </Helmet>

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_KITCHEN_INVENTORY_TRANSACTION,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) ? (
        <KitchenPurchaseOrdersListView />
      ) : (
        <Navigate to="/403" />
      )}
    </>
  );
}
