import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { APP_ENVIRONMENT } from 'src/config-global';

import Label from '../label';
import { useSettingsContext } from '../settings';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();

    const logo =
      settings.themeLayout !== 'mini' ? (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 40,
            height: 'auto',
            display: 'inline-flex',
            position: 'relative',
            ...sx,
          }}
          {...other}
        >
          <img src="/logo/pubsira-logo.png" alt="Pub Sira" style={{ objectFit: 'contain' }} />
          {APP_ENVIRONMENT !== 'production' && (
            <Label
              variant="soft"
              color="error"
              sx={{ ml: 1, position: 'absolute', top: 0, right: '-250%' }}
            >
              <Typography variant="caption" sx={{ fontSize: '10px' }}>
                {APP_ENVIRONMENT}
              </Typography>
            </Label>
          )}
        </Box>
      ) : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 'auto',
            height: 40,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
        >
          <img src="/logo/pubsira-logo.png" alt="Pub Sira" style={{ objectFit: 'contain' }} />
        </Box>
      );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href={paths.dashboard.root} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
