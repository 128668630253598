import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, Messaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBhT77CQlQLMXG1FWjrfZAeb8RN8IHSDB0',
  authDomain: 'pub-sira.firebaseapp.com',
  projectId: 'pub-sira',
  storageBucket: 'pub-sira.appspot.com',
  messagingSenderId: '251719860566',
  appId: '1:251719860566:web:4d6debd215b244b9d556f7',
  measurementId: 'G-H940JYJZL6',
};

const app = initializeApp(firebaseConfig);

// eslint-disable-next-line import/no-mutable-exports
let messaging: Messaging | null = null;

try {
  messaging = getMessaging(app);
} catch (e) {
  console.warn('Messaging is not supported');
  console.warn(e);
}

const analytics = getAnalytics(app);

export { messaging, analytics };
