import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputAdornment,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAppDispatcher } from 'src/redux/store';
import { changePassword, IListUser, TUserResetPassword } from 'src/redux/slices/users';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

export type ConditionalSchema<T> = T extends string
  ? Yup.StringSchema
  : T extends number
  ? Yup.NumberSchema
  : T extends boolean
  ? Yup.BooleanSchema
  : T extends Record<any, any>
  ? Yup.AnyObjectSchema
  : T extends Array<any>
  ? Yup.ArraySchema<any, any>
  : Yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

type Props = {
  user: IListUser;
  onClose: VoidFunction;
};

function UserResetPassword({ user, open, onClose, title, ...other }: Props & DialogProps) {
  const dispatch = useAppDispatcher();
  const password = useBoolean();
  const confirmPassword = useBoolean();

  const UserResetPasswordSchema = Yup.object<Shape<TUserResetPassword>>().shape({
    password: Yup.string()
      .min(4, 'At least 4 characters required')
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const defaultValues = useMemo(
    () => ({
      password: '',
      confirmPassword: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(UserResetPasswordSchema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await dispatch(changePassword({ userId: user._id, password: data.password })).unwrap();

      enqueueSnackbar('Password reset successfully');

      onClose();
    } catch (e) {
      if (typeof e === 'string') {
        enqueueSnackbar(e, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(e?.message, {
          variant: 'error',
        });
      }
    }
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>Reset Password</DialogTitle>

      <DialogContent sx={{ typography: 'body2' }}>
        Are you sure want to reset {user.firstName}&apos;s password?
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <RHFTextField
            required
            name="password"
            label="New Password"
            type={password.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-test-id': 'login_password',
            }}
            sx={{ mt: 2 }}
          />
          <RHFTextField
            required
            name="confirmPassword"
            label="Confirm Password"
            type={confirmPassword.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={confirmPassword.onToggle} edge="end">
                    <Iconify
                      icon={confirmPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-test-id': 'login_password',
            }}
            sx={{ mt: 2 }}
          />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={onSubmit}>
          Reset Password
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default UserResetPassword;
