import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useCallback, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Divider,
  Link,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

import { fDateTime } from 'src/utils/format-time';
import { hasPermission } from 'src/utils/has-permissions';
import { fCurrencyRupees } from 'src/utils/format-number';
import {
  TABLE_HEAD_CELL_TYPE,
  TRANSACTION_DISPATCH_TYPES,
  TRANSACTION_STATUS,
} from 'src/utils/common-types';

import { useAuthContext } from 'src/auth/hooks';
import { useAppDispatcher } from 'src/redux/store';
import { AuthPermissions } from 'src/auth/permissions';
import { kitchenDispatchOrderMarkAsCompleted } from 'src/redux/slices/kitchen-dispatch-order';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';

import { IKitchenDispatchOrderDetailedItem } from 'src/types/kitchen-dispatches';

import { paths } from '../../routes/paths';

const LINE_ITEM_HEADERS: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'itemName',
    label: 'Item Name',
    align: 'left',
    primary: true,
    minWidth: 300,
  },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },
];

const LINE_ITEM_HEADERS_RETURN: TABLE_HEAD_CELL_TYPE[] = [
  { id: 'itemNo', label: '#', align: 'left', primary: true },
  {
    id: 'itemName',
    label: 'Item Name',
    align: 'left',
    primary: true,
    minWidth: 300,
  },
  { id: 'quantity', label: 'Quantity', align: 'left', primary: true },
  {
    id: 'unitPrice',
    label: 'Unit Price',
    align: 'right',
    primary: true,
  },
  { id: 'discount', label: 'Discount', align: 'right', primary: true },
  { id: 'total', label: 'Total', align: 'right', primary: true },
];

type Props = {
  currentKitchenDispatchOrder: IKitchenDispatchOrderDetailedItem;
};

export default function KitchenDispatchOrderDetails({ currentKitchenDispatchOrder }: Props) {
  const dispatch = useAppDispatcher();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user: authUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const markAsCompleted = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(
        kitchenDispatchOrderMarkAsCompleted({
          dispatchOrderId: currentKitchenDispatchOrder?._id,
          dispatchOrder: currentKitchenDispatchOrder,
        })
      ).unwrap();
      setIsLoading(false);

      enqueueSnackbar('Kithen dipatch order marked as completed');
      navigate(paths.dashboard.kitchen.dispatch.root());
    } catch (e) {
      if (typeof e === 'string') {
        enqueueSnackbar(e, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(e?.message, {
          variant: 'error',
        });
      }
      setIsLoading(false);
    }
  }, [dispatch, currentKitchenDispatchOrder, enqueueSnackbar, navigate]);

  const hasRequiredPermissions =
    (currentKitchenDispatchOrder.status === TRANSACTION_STATUS.DRAFT &&
      hasPermission(
        authUser,
        'any',
        AuthPermissions.CREATE_KITCHEN_INVENTORY_TRANSACTION,
        AuthPermissions.UPDATE_KITCHEN_INVENTORY_TRANSACTION,
        AuthPermissions.DELETE_KITCHEN_INVENTORY_TRANSACTION,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      )) ||
    (currentKitchenDispatchOrder.status !== TRANSACTION_STATUS.DRAFT &&
      hasPermission(
        authUser,
        'any',
        AuthPermissions.UPDATE_KITCHEN_INVENTORY_TRANSACTION,
        AuthPermissions.DELETE_KITCHEN_INVENTORY_TRANSACTION,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ));

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Card sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid
              mb={1}
              display="flex"
              mt={2}
              alignItems="center"
              justifyContent="space-between"
              width={1}
            >
              <Typography variant="subtitle2">Basic Information</Typography>

              {hasRequiredPermissions && (
                <Link
                  component={RouterLink}
                  href={paths.dashboard.kitchen.dispatch.edit(currentKitchenDispatchOrder._id)}
                >
                  <Button variant="text" startIcon={<Iconify icon="solar:pen-bold" />}>
                    Edit
                  </Button>
                </Link>
              )}
            </Grid>

            <Grid xs={12} md={4}>
              <ListItemText
                primary="Dispatched Date"
                secondary={fDateTime(currentKitchenDispatchOrder?.purchaseOrDispatchDate)}
                primaryTypographyProps={{ typography: 'body2' }}
                secondaryTypographyProps={{
                  component: 'span',
                  color: 'text.disabled',
                }}
              />
            </Grid>

            <Grid xs={12} md={4}>
              <ListItemText
                primary="Transaction No"
                secondary={
                  currentKitchenDispatchOrder?.status === TRANSACTION_STATUS.COMPLETED
                    ? currentKitchenDispatchOrder?.transactionCode
                    : 'N/A'
                }
                primaryTypographyProps={{ typography: 'body2' }}
                secondaryTypographyProps={{
                  component: 'span',
                  color: 'text.disabled',
                }}
              />
            </Grid>

            <Grid xs={12} md={4}>
              <ListItemText
                primary="Dispatch Type"
                secondary={
                  <Label variant="soft" color="default">
                    {currentKitchenDispatchOrder?.transactionType?.split('_').join(' ')}
                  </Label>
                }
                primaryTypographyProps={{ typography: 'body2' }}
                secondaryTypographyProps={{
                  component: 'span',
                  color: 'text.disabled',
                }}
              />
            </Grid>

            {currentKitchenDispatchOrder.transactionType === TRANSACTION_DISPATCH_TYPES.RETURN && (
              <>
                <Grid xs={12} md={4}>
                  <ListItemText
                    primary="Discount"
                    secondary={fCurrencyRupees(
                      Number(currentKitchenDispatchOrder?.discount ?? 0) * 100
                    )}
                    primaryTypographyProps={{ typography: 'body2' }}
                    secondaryTypographyProps={{
                      component: 'span',
                      color: 'text.disabled',
                    }}
                  />
                </Grid>

                <Grid xs={12} md={4}>
                  <ListItemText
                    primary="Grand Total"
                    secondary={fCurrencyRupees(
                      Number(currentKitchenDispatchOrder?.totalAfterDiscount ?? 0) * 100
                    )}
                    primaryTypographyProps={{ typography: 'body2' }}
                    secondaryTypographyProps={{
                      component: 'span',
                      color: 'text.disabled',
                    }}
                  />
                </Grid>
              </>
            )}

            <Divider
              sx={{
                width: 1,
                my: 2,
                borderStyle: 'dashed',
              }}
            />

            <Grid mb={1} xs={12} width="full">
              <Typography variant="subtitle2">Line Items</Typography>
            </Grid>

            <Grid xs={12}>
              <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '300px' }}>
                <Scrollbar>
                  <Table stickyHeader size="small" sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      headLabel={
                        currentKitchenDispatchOrder.transactionType ===
                        TRANSACTION_DISPATCH_TYPES.RETURN
                          ? LINE_ITEM_HEADERS_RETURN
                          : LINE_ITEM_HEADERS
                      }
                    />

                    <TableBody>
                      {currentKitchenDispatchOrder?.items.map((item, index) => (
                        <TableRow hover key={item._id}>
                          <TableCell align="left">
                            {index + 1 < 10 ? `0${index + 1}` : index + 1}
                          </TableCell>

                          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                            {item.inventoryItem.itemName}
                          </TableCell>

                          <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                            {item.quantity} {item.unit.abbreviation}
                          </TableCell>

                          {currentKitchenDispatchOrder.transactionType ===
                            TRANSACTION_DISPATCH_TYPES.RETURN && (
                            <>
                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                                {fCurrencyRupees((item.unitPrice ?? 0) * 100)}
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                                {fCurrencyRupees((item.discount ?? 0) * 100)}
                              </TableCell>

                              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                                {fCurrencyRupees((item.totalAfterDiscount ?? 0) * 100)}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            </Grid>
          </Grid>

          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Link component={RouterLink} href={paths.dashboard.kitchen.dispatch.root()}>
              <Button variant="outlined" color="inherit">
                Cancel
              </Button>
            </Link>

            {currentKitchenDispatchOrder.status === TRANSACTION_STATUS.DRAFT && (
              <LoadingButton
                type="button"
                variant="contained"
                loading={isLoading}
                onClick={markAsCompleted}
              >
                Mark as completed
              </LoadingButton>
            )}
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
