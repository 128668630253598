import { Helmet } from 'react-helmet-async';

import { APP_BRAND_NAME } from 'src/config-global';

import { JwtLoginView } from 'src/sections/auth/jwt';

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> {`${APP_BRAND_NAME} Login`}</title>
      </Helmet>

      <JwtLoginView />
    </>
  );
}
