import { logEvent } from 'firebase/analytics';

import { analytics } from 'src/firebase';

export enum APP_EVENTS {
  SIDE_BAR_TOGGLE = 'sidebar_toggle',
  FULLSCREEN_MODE_TOGGLE = 'fullscreen_toggle',
  TABLE_COMPACT_MODE_TOGGLE = 'table_compact_mode_toggle',
  RESET_PASSWORD = 'reset_password',
  TABLE_PAGINATION_MORE_THAN_TWO = 'table_pagination_more_than_two',
}

export default function logToucodeEvent(event: APP_EVENTS, data?: { [key: string]: any }) {
  logEvent(analytics, event, data);
}
