import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IUnitListItem } from 'src/types/unit';

import axios from '../../utils/axios';

interface IInitialState {
  status: 'idle' | 'loading' | 'success' | 'failure';
  error: string | undefined;
  units: {
    count: number;
    page: number;
    pageSize: number;
    pageCount: number;
    units: IUnitListItem[];
  };
}

const initialState: IInitialState = {
  status: 'idle',
  error: undefined,
  units: {
    count: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    units: [],
  },
};

const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedUnits.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaginatedUnits.fulfilled, (state, action) => {
        state.status = 'success';
        state.units = action.payload;
      })
      .addCase(getPaginatedUnits.rejected, (state, action) => {
        state.status = 'failure';
        state.error = action.error.message;
      });
  },
});

export default unitsSlice.reducer;

export const getPaginatedUnits = createAsyncThunk(
  'units/getPaginated',
  async (
    params: {
      page?: number | null;
      limit?: number | null;
      generalSearch?: string | null;
      name?: string | null;
      abbreviation?: string | null;
      category?: string | null;
      sortBy?: string | null;
      sort?: 'asc' | 'desc' | null;
    } = {
      page: null,
      limit: null,
      generalSearch: null,
      name: null,
      abbreviation: null,
      category: null,
      sort: null,
      sortBy: null,
    }
  ) => {
    const { page, limit, generalSearch, category, sortBy, sort, abbreviation, name } = params;

    try {
      const response = await axios.get(
        `unit?page=${page ? page + 1 : ''}&limit=${limit ?? ''}&generalSearch=${
          generalSearch ?? ''
        }&name=${name ?? ''}&abbreviation=${abbreviation ?? ''}&category=${category ?? ''}&sortBy=${
          sortBy ?? ''
        }&sort=${sort ?? ''}`
      );

      return response.data.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
