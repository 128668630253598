import { isEqual } from 'lodash';
import * as Sentry from '@sentry/react';
import { useDebounce } from 'use-debounce';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { TAB_QUERY } from 'src/utils/common-types';
import { hasPermission } from 'src/utils/has-permissions';
import logToucodeEvent, { APP_EVENTS } from 'src/utils/log-google-events';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';
import { useAppDispatcher, useAppSelector } from 'src/redux/store';
import { getPaginatedPermissionsAsList, IPermission } from 'src/redux/slices/permissions';
import {
  deleteUser,
  getPaginatedUsers,
  IListUser,
  removeUser,
  UserStatus,
} from 'src/redux/slices/users';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TableErrorData from 'src/components/table/table-error-data';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { IUserTableFilters, IUserTableFilterValue, IUserTableSort } from 'src/types/user';

import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';

const USER_STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'blocked', label: 'Blocked' },
];

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'firstName', label: 'Name', align: 'left', primary: true },
  { id: 'jobRole', label: 'Job Role', align: 'left', primary: true },
  { id: 'status', label: 'Status', align: 'left', primary: true },
  { id: 'updatedAt', label: 'Updated', align: 'left', primary: true },
  { id: '', primary: true },
];

const defaultFilters: IUserTableFilters = {
  name: '',
  permissions: [],
};

const defaultSortBy: IUserTableSort = {
  sortBy: 'updatedAt',
  order: 'desc',
};

function getActiveTab(param: string): string {
  switch (param) {
    case 'all':
      return 'all';
    case 'blocked':
      return 'blocked';
    default:
      return UserStatus.ACTIVE;
  }
}

function getUserStatus(param: string): UserStatus[] {
  switch (param) {
    case 'all':
      return [UserStatus.ACTIVE, UserStatus.DISABLED];
    case 'blocked':
      return [UserStatus.DISABLED];
    default:
      return [UserStatus.ACTIVE];
  }
}

export default function UserListView() {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get(TAB_QUERY);

  const { status, users, error } = useAppSelector((state) => state.users);
  const { list } = useAppSelector((state) => state.permissions);

  const { user: authUser } = useAuthContext();

  const table = useTable({
    defaultRowsPerPage: 25,
  });

  const dispatch = useAppDispatcher();

  const { page, rowsPerPage } = table;

  const settings = useSettingsContext();

  const denseHeight = table.dense ? 60 : 80;

  const router = useRouter();

  const [query, setQuery] = useState<string>('');
  const [searchQuery] = useDebounce(query, 500);

  const [filters, setFilters] = useState(defaultFilters);

  const [sort, setSort] = useState(defaultSortBy);

  const canReset = !isEqual(defaultFilters, filters);

  const [userStatus, setUserStatus] = useState<UserStatus[]>(
    getUserStatus(currentTab ?? UserStatus.ACTIVE)
  );

  const [statusTab, setStatusTab] = useState<string>(getActiveTab(currentTab ?? UserStatus.ACTIVE));

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      table.onResetPage();

      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setStatusTab(newValue);

      setSearchParams({ activeTab: newValue });

      switch (newValue) {
        case 'active':
          setUserStatus([UserStatus.ACTIVE]);
          break;
        case 'blocked':
          setUserStatus([UserStatus.DISABLED]);
          break;
        default:
          setUserStatus([UserStatus.ACTIVE, UserStatus.DISABLED]);
          break;
      }
    },
    [setSearchParams]
  );

  const handleResetFilters = useCallback(() => {
    setQuery('');
    setFilters(defaultFilters);
    setUserStatus([UserStatus.ACTIVE, UserStatus.DISABLED]);
  }, []);

  const fetchPaginatedUsers = useCallback(() => {
    dispatch(
      getPaginatedUsers({
        page,
        limit: rowsPerPage,
        firstName: searchQuery,
        email: searchQuery,
        lastName: searchQuery,
        permissions: list.permissions
          .filter((permission: IPermission) => filters.permissions.includes(permission.description))
          .map((permission: IPermission) => permission._id),
        sort: sort.order,
        sortBy: sort.sortBy,
        status: userStatus,
      })
    );

    if (page > 1) {
      logToucodeEvent(APP_EVENTS.TABLE_PAGINATION_MORE_THAN_TWO, {
        page,
        rowsPerPage,
      });
    }
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchQuery,
    list.permissions,
    filters.permissions,
    sort.order,
    sort.sortBy,
    userStatus,
  ]);

  const handleDeleteRow = useCallback(
    async (user: IListUser, index: number) => {
      Sentry.setUser({
        id: authUser?._id,
        email: authUser?.email,
      });

      Sentry.captureEvent({
        message: 'User deletion action',
        level: 'info',
        tags: {
          action: 'delete_user',
          userId: user._id,
        },
        extra: {
          user,
        },
      });

      dispatch(removeUser({ userId: user._id }));

      try {
        await dispatch(deleteUser({ user, index })).unwrap();

        fetchPaginatedUsers();
        enqueueSnackbar('User deleted');
      } catch (e) {
        if (typeof e === 'string') {
          enqueueSnackbar(e, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(e?.message, {
            variant: 'error',
          });
        }
      }
    },
    [dispatch, fetchPaginatedUsers, authUser]
  );

  useEffect(() => {
    fetchPaginatedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPaginatedUsers]);

  useEffect(() => {
    dispatch(getPaginatedPermissionsAsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Users"
        links={[]}
        action={
          <>
            {hasPermission(
              authUser,
              'any',
              AuthPermissions.CREATE_USER,
              AuthPermissions.ADMIN_PERMISSION,
              AuthPermissions.SUPER_ADMIN_PERMISSION
            ) && (
              <Button
                component={RouterLink}
                href={paths.dashboard.user.new}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New User
              </Button>
            )}
          </>
        }
        sx={{
          mb: { xs: 3 },
        }}
      />

      <Card>
        <Tabs
          value={statusTab}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
          ))}
        </Tabs>

        <UserTableToolbar
          filters={filters}
          onFilters={handleFilters}
          query={query}
          onHandleSearch={(value: string) => {
            setQuery(value);
            table.setPage(0);
          }}
          permissions={list.permissions}
          handleResetFilters={handleResetFilters}
          canReset={canReset}
        />

        <TableContainer sx={{ position: 'relative', overflow: 'hidden', height: '450px' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={sort.order}
                orderBy={sort.sortBy}
                headLabel={TABLE_HEAD.filter(({ primary }) => {
                  if (settings.themeLayout === 'vertical') {
                    return primary;
                  }
                  return true;
                })}
                rowCount={users.users.length}
                numSelected={table.selected.length}
                onSort={(id) => {
                  if (id === sort.sortBy) {
                    setSort((prev) => ({
                      ...prev,
                      order: prev.order === 'asc' ? 'desc' : 'asc',
                    }));
                  } else {
                    setSort({
                      sortBy: id,
                      order: 'asc',
                    });
                  }
                }}
              />

              <TableBody>
                {status === 'loading' ? (
                  [...Array(table.rowsPerPage)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: denseHeight }} />
                  ))
                ) : (
                  <>
                    {users?.users.map((row: IListUser, index: number) => (
                      <UserTableRow
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                        onDeleteRow={() => handleDeleteRow(row, index)}
                        onEditRow={() => handleEditRow(row._id)}
                      />
                    ))}
                  </>
                )}

                {status !== 'failure' && users.count === 0 && (
                  <TableNoData message="No users found" notFound={users.count === 0} />
                )}

                {status === 'failure' && <TableErrorData notFound error={error} />}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={users.count}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={(value) => {
            table.onChangeDense(value);

            logToucodeEvent(APP_EVENTS.TABLE_COMPACT_MODE_TOGGLE, {
              compact: value,
              table: 'users_table',
            });
          }}
        />
      </Card>
    </Container>
  );
}
