import { Controller, useFormContext } from 'react-hook-form';

import TextField, { TextFieldProps } from '@mui/material/TextField';

type Props = TextFieldProps & {
  name: string;
  showError?: boolean;
};

export default function RHFTextField({
  name,
  showError = true,
  helperText,
  type,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          onKeyDown={(event) => {
            if (type === 'number') {
              if (event.key === 'ArrowUp') {
                event.preventDefault();

                field.onChange(field.value + 1);
              }
              if (event.key === 'ArrowDown') {
                event.preventDefault();

                field.onChange(field.value - 1);
              }
            }
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          error={!!error}
          helperText={showError && error ? error?.message : helperText}
          inputProps={{
            autoComplete: 'off',
          }}
          {...other}
        />
      )}
    />
  );
}
