import { Navigate } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { hasPermission } from 'src/utils/has-permissions';

import { useAuthContext } from 'src/auth/hooks';
import { AuthPermissions } from 'src/auth/permissions';

import { UserListView } from 'src/sections/user/view';

export default function UserListPage() {
  const { user: authUser } = useAuthContext();

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>

      {hasPermission(
        authUser,
        'any',
        AuthPermissions.READ_USER,
        AuthPermissions.ADMIN_PERMISSION,
        AuthPermissions.SUPER_ADMIN_PERMISSION
      ) ? (
        <UserListView />
      ) : (
        <Navigate to="/403" />
      )}
    </>
  );
}
